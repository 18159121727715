import { currency } from "utils/helpers";

export default function OrderInvoicesList(props) {
  const { invoices } = props;

  return (
    <section>
      <h2 className="text-lg font-semibold mb-2 mx-2 sr-only">Invoices</h2>
      <div className="flex flex-col gap-2">
        {invoices.map((invoice) => (
          <div key={invoice.id} className="bg-white p-6 w-full rounded">
            <div className="flex flex-col md:flex-row md:justify-between gap-2">
              <div>
                <h3 className="font-bold">{invoice?.location?.label ?? "-"}</h3>
              </div>
              <div>
                <div className="flex flex-wrap items-center gap-3">
                  {/* total */}
                  <p className="text-sm text-gray-600">
                    Total:{" "}
                    <span className="text-base font-mono text-black">
                      {currency(invoice.total)}
                    </span>
                  </p>
                  {/* costs.expenses */}
                  {!!invoice?.costs?.expenses && (
                    <p className="text-sm text-gray-600">
                      Incl. Expense:{" "}
                      <span className="text-sm font-mono text-black">
                        {currency(invoice?.costs.expenses)}
                      </span>
                    </p>
                  )}
                  {/* invoice button */}
                  {invoice.link && (
                    <a
                      href={invoice.link}
                      className="btn btn-xs btn-outline text-primary"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Xero Invoice
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
