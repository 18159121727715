import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_NOTIFIABLE_ITEMS } from "utils/queries";
import { UPDATE_ORDER_ITEM, UPDATE_ORDER } from "utils/mutations";
import QuantityInput from "components/partials/Quantity";
import { formatISODate, unit } from "utils/helpers";
import { notifiedItemsList } from "utils/pdf";
import clsx from "clsx";
import LoadingLayout from "layouts/Loading";

export default function NotifyItemsList(props) {
  const { order, info, refreshOrder } = props;

  const { module, number, date } = info;

  const [items, setItems] = useState([]);
  const [updating, setUpdating] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [name, setName] = useState("");

  const [debouncedName] = useDebounce(name, 500);

  const { loading: fetching, refetch } = useQuery(LIST_NOTIFIABLE_ITEMS, {
    variables: {
      order,
      supplier: !!selectedSupplier ? selectedSupplier : undefined,
      name: !!debouncedName ? debouncedName : null,
    },
    onCompleted: (data) => {
      if (data.items?.success) {
        setItems(data.items.list);
        setSuppliers(data.items?.options?.suppliers);
      }
    },
    onError: (error) => {
      console.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [notifyItem] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      setUpdating(null);
      if (data?.updated?.success) {
        toast.success("Item updated successfully");
        refetch();
      }
      if (!data?.updated?.success) toast.error("Item update failed");
    },
    onError: (error) => {
      setUpdating(null);
      toast.error(error.message);
      console.error(error);
    },
  });

  const [processOrder, { loading: processing }] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      if (data?.updated?.success) {
        toast.success("Order processed successfully");
        refetch();
        refreshOrder();
      }
      if (!data?.updated?.success) toast.error("Order processed failed");
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const handleNotify = (id, qty) => {
    // uses item id and quantity
    const inputs = {
      id,
      notify: {
        supplier: selectedSupplier,
        quantity: qty,
      },
    };
    setUpdating(id);

    notifyItem({ variables: { inputs } });
  };

  const handlePrint = (type) => {
    const name = !!selectedSupplier
      ? suppliers.find((sup) => sup.id === selectedSupplier).label
      : "All Suppliers";

    const payload = {
      number,
      date: formatISODate(date, "DD/MM/YYYY"),
      name,
      items,
    };

    if (type === "list") notifiedItemsList(payload);
  };

  const handleProcess = () => {
    if (!window.confirm("Are you sure you want to process this order?")) return;
    processOrder({
      variables: { inputs: { id: order, status: "PROCESSING" } },
    });
  };

  return (
    <>
      <section>
        <div className="flex flex-wrap flex-col md:flex-row md:justify-between md:items-baseline mb-4">
          {/* title */}
          <h2 className="text-lg font-semibold mb-2 mx-2">Notify Items</h2>
          {/* select supplier */}
          <div className="flex flex-row gap-2 items-center mx-2 flex-wrap md:flex-nowrap">
            <div className="flex gap-2">
              <button
                className={clsx(
                  "btn btn-sm btn-outline btn-primary",
                  processing && "loading"
                )}
                onClick={handleProcess}
              >
                Process Order
              </button>
              {module === "PRODUCE" && (
                <button
                  className="btn btn-sm btn-outline"
                  onClick={() => handlePrint("list")}
                >
                  Print List
                </button>
              )}
            </div>
            <div>
              <label
                htmlFor="supplier"
                className="text-sm font-semibold sr-only"
              >
                Supplier
              </label>
              <select
                name="supplier"
                id="supplier"
                className="rounded border-2 border-error w-full"
                disabled={fetching || !!updating}
                onChange={(e) => setSelectedSupplier(e.target.value)}
              >
                <option value="">All suppliers</option>
                {suppliers.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* filter by name */}
        <div className="my-2">
          <input
            type="search"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Search products"
            className="input w-full bg-white"
          />
        </div>
        {fetching ? (
          <LoadingLayout />
        ) : (
          <>
            {items && items.length > 0 ? (
              <>
                <ul className="flex flex-col gap-1">
                  {items.map((value, index) => (
                    <li className="flex bg-white rounded px-1 py-1" key={index}>
                      <div className="flex flex-col flex-1 px-1 py-2">
                        <div className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
                          {/* product info */}
                          <div className="flex flex-row gap-2 items-center">
                            <div className="font-bold font-mono text-xs">
                              #{index + 1}
                            </div>
                            <div className="relative z-10 flex-shrink-0 w-12">
                              <img
                                src={value.thing.thumbnail}
                                className="min-w-12 min-h-12 w-12 h-12 object-cover rounded"
                                alt={value.thing.label ?? "-"}
                              />
                              {value.thing?.flags?.highlighted && (
                                <span className="absolute -top-1 -left-1 flex h-2 w-2">
                                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
                                  <span className="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
                                </span>
                              )}
                            </div>
                            <div className="flex-1 flex-shrink-0 px-2">
                              <h3 className="text-lg font-semibold tracking-wider">
                                {value.thing.label ?? "-"}{" "}
                              </h3>
                              <span className="text-xs font-semibold">
                                {value.thing.group ?? "-"}
                              </span>{" "}
                              -{" "}
                              <span className="text-xs">
                                {value.thing.unit ?? "-"}
                              </span>
                            </div>
                          </div>
                          {/* parameters */}
                          <div className="flex flex-row justify-center items-center md:justify-end gap-2">
                            <div>
                              <p className="text-sm">
                                Total Request:{" "}
                                <span className="font-mono font-bold text-md">
                                  {unit(value.requested)}
                                </span>
                              </p>
                            </div>
                            <QuantityInput
                              id={value.thing.id}
                              label="Notify"
                              value={value.quantity}
                              update={handleNotify}
                              disabled={!selectedSupplier}
                              updating={updating}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <p className="text-center pt-2 text-xs tracking-wider font-bold">
                  Total: {items.length} items
                </p>
              </>
            ) : (
              <p className="text-center text-gray-500">No items found</p>
            )}
          </>
        )}
      </section>
    </>
  );
}
