import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_SUPPLIER, UPDATE_SUPPLIER } from "utils/mutations";
import { GET_SUPPLIER, LIST_SUPPLIERS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function SupplierForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const { supplier_module } = watch();

  const [createSupplier] = useMutation(CREATE_SUPPLIER, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.created?.success)
        toast.error("Supplier creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Supplier created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SUPPLIERS }],
  });

  const [updateSupplier] = useMutation(UPDATE_SUPPLIER, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.updated?.success)
        toast.error("Supplier update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Supplier updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SUPPLIERS }],
  });

  const [getSupplier] = useLazyQuery(GET_SUPPLIER, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.supplier?.success) {
        const { supplier } = data.supplier;
        setValue("supplier_module", supplier?.module);
        setValue("supplier_name", supplier?.name);
        if (supplier?.email) setValue("supplier_email", supplier?.email);
        if (supplier?.mobile) setValue("supplier_mobile", supplier?.mobile);
        if (supplier?.availability)
          setValue("supplier_availability", supplier?.availability);
        setValue("supplier_status", supplier?.status);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (mode === "edit") {
      updateSupplier({
        variables: {
          inputs: {
            id,
            module: data.supplier_module,
            name: data.supplier_name,
            email: data.supplier_email,
            mobile: data.supplier_mobile,
            availability: data.supplier_availability,
            status: data.supplier_status,
          },
        },
      });
    } else {
      createSupplier({
        variables: {
          inputs: {
            module: data.supplier_module,
            name: data.supplier_name,
            email: data.supplier_email,
            availability: data.supplier_availability,
            mobile: data.supplier_mobile,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getSupplier({ variables: { id } });
    }
  }, [mode, id, getSupplier]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* module */}
        <div>
          <label htmlFor="module" className="label">
            <span className="label-text font-semibold">Module</span>
          </label>
          <select
            name="module"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("supplier_module", {
              required: "Module is required",
              validate: (value) => {
                if (value === "PRODUCE") return true;
                if (value === "GROCERY") return true;
                // if (value === "FOOD") return true;
                return "Invalid module";
              },
            })}
          >
            <option value="">Select module</option>
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD" disabled>
              Food
            </option>
          </select>
          {errors?.supplier_module && (
            <div className="mt-1 text-red-600">
              <small>{errors?.supplier_module?.message}</small>
            </div>
          )}
        </div>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Supplier name"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("supplier_name", {
              required: "Name is required",
            })}
          />
          {errors?.supplier_name && (
            <div className="mt-1 text-red-600">
              <small>{errors?.supplier_name?.message}</small>
            </div>
          )}
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
            <span className="label-text-alt">
              For Grocery & Produce modules
            </span>
          </label>
          <input
            type="email"
            name="email"
            inputMode="email"
            placeholder="Supplier email"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("supplier_email", {
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email",
              },
            })}
          />
          {errors?.supplier_email && (
            <div className="mt-1 text-red-600">
              <small>{errors?.supplier_email?.message}</small>
            </div>
          )}
        </div>
        {/* mobile */}
        <div>
          <label htmlFor="mobile" className="label">
            <span className="label-text font-semibold">Mobile</span>
            <span className="label-text-alt">For Produce module</span>
          </label>
          <input
            type="text"
            name="mobile"
            inputMode="tel"
            placeholder="Supplier mobile (+61412345678)"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("supplier_mobile", {
              pattern: {
                value: /^\+614[0-9]{8}/i,
                message:
                  "Enter valid australian mobile along with country code",
              },
            })}
          />
          {errors?.supplier_mobile && (
            <div className="mt-1 text-red-600">
              <small>{errors?.supplier_mobile?.message}</small>
            </div>
          )}
        </div>
        {/* availability */}
        {supplier_module === "PRODUCE" && (
          <div>
            <label htmlFor="permissions" className="label">
              <span className="label-text font-semibold">Availability</span>
            </label>
            <select
              name="permissions"
              className="input input-bordered w-full bg-base-200 space-y-2"
              disabled={loading}
              {...register("supplier_availability", {
                required: "Availability is required",
              })}
            >
              <option value="">Select availability</option>
              <option value="WEEKDAYS">Weekdays</option>
              <option value="WEEKEND">Weekend</option>
            </select>
            {errors?.supplier_availability && (
              <div className="mt-1 text-red-600">
                <small>{errors?.supplier_availability?.message}</small>
              </div>
            )}
          </div>
        )}
        {/* status */}
        {mode === "edit" ? (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("supplier_status", {
                required: "Status is required",
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            {errors?.supplier_status && (
              <div className="mt-1 text-red-600">
                <small>{errors?.supplier_status?.message}</small>
              </div>
            )}
          </div>
        ) : null}
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update supplier" : "Create supplier"}
          </button>
        </div>
      </form>
    </div>
  );
}
