import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import ProductsTable from "components/tables/Products";

export default function Products(props) {
  return (
    <UserLayout title="Products">
      <HeadSection heading="Products">
        <Link to={`/create/product`} className="btn btn-secondary btn-outline">
          Add Product
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <ProductsTable />
      </section>
    </UserLayout>
  );
}
