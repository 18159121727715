export default function ItemsStats(props) {
  const { summary } = props;

  const { requests, deliveries } = summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded rounded-b-none w-full bg-base-200 border-b">
      <div className="stat">
        <div className="stat-title">Requests</div>
        <div className="stat-value font-bold text-xl">{requests}</div>
        <div className="stat-desc">Items requests</div>
      </div>

      <div className="stat">
        <div className="stat-title">Deliveries</div>
        <div className="stat-value font-bold text-xl">{deliveries}</div>
        <div className="stat-desc">Items deliveries</div>
      </div>
    </div>
  );
}
