import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrdersTable from "components/tables/Orders";

export default function Orders(props) {
  return (
    <UserLayout title="Orders">
      <HeadSection heading="Orders">
        <Link to={`/create/order`} className="btn btn-secondary btn-outline">
          Create Order
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <OrdersTable />
      </section>
    </UserLayout>
  );
}
