import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function InformationSection(props) {
  const { title = "Information" } = props;

  return (
    <section>
      <h2 className="text-lg font-semibold mb-2 mx-2">
        <InformationCircleIcon className="inline-block w-6 h-6 mr-1" />
        {title}
      </h2>
      <div className="pt-4 pb-6 flex flex-col gap-2 text-sm border-b-2 bg-white rounded">
        <div className="ml-6 flex flex-col gap-1">{props.children}</div>
      </div>
    </section>
  );
}
