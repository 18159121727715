import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_ORDER, UPDATE_ORDER } from "utils/mutations";
import { GET_ORDER, LIST_ORDERS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { formatISODate } from "utils/helpers";

export default function OrderForm(props) {
  const { id } = props;

  const now = `${new Date()
    .toLocaleDateString()
    .split("/")
    .reverse()
    .join("-")}T${new Date(
    // set time to 1 hour ahead
    new Date().getTime() + 1 * 60 * 60 * 1000
  ).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [createOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.created?.success)
        toast.error("Order creation failed, please check the inputs");
      if (data?.created?.success) {
        toast.success("Order created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_ORDERS }],
  });

  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.updated?.success)
        toast.error("Order update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Order updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_ORDERS }],
  });

  const [getOrder] = useLazyQuery(GET_ORDER, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.order?.success) {
        const { order } = data.order;
        setValue("order_module", order?.module);
        setValue(
          "order_close",
          formatISODate(order?.close, "yyyy-MM-DDTHH:mm")
        );
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    setLoading(true);

    const payload = {
      module: data.order_module,
      close: data.order_close,
    };

    const inputs = mode === "edit" ? { id, ...payload } : payload;

    if (mode === "edit") updateOrder({ variables: { inputs } });
    else createOrder({ variables: { inputs } });
  };

  useEffect(() => {
    if (mode === "edit") {
      setLoading(true);
      getOrder({ variables: { id } });
    }
  }, [mode, id, getOrder]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* module */}
        <div>
          <label htmlFor="module" className="label">
            <span className="label-text font-semibold">Module</span>
          </label>
          <select
            name="module"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("order_module", {
              required: "Module is required",
            })}
          >
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD">Food</option>
          </select>
          {errors?.order_module && (
            <div className="mt-1 text-red-600">
              <small>{errors?.order_module?.message}</small>
            </div>
          )}
        </div>
        {/* close */}
        <div>
          <label htmlFor="close_time" className="label">
            <span className="label-text font-semibold">Close Time</span>
          </label>
          <input
            type="datetime-local"
            name="close_time"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            min={now}
            {...register("order_close", {
              required: "Close time is required",
              min: {
                value: now,
                message: "Close time must be in the future",
              },
            })}
          />
          {errors?.order_close_time && (
            <div className="mt-1 text-red-600">
              <small>{errors?.order_close_time?.message}</small>
            </div>
          )}
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update order" : "Create order"}
          </button>
        </div>
      </form>
    </div>
  );
}
