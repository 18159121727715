import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "utils/queries";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrderStats from "components/stats/Order";
import OrderInvoicesList from "components/lists/OrderInvoices";
import NotifyItemsList from "components/lists/NotifyItems";
import ItemsTable from "components/tables/Items";
import OrderExpensesList from "components/lists/OrderExpenses";
import CommentsList from "components/lists/Comments";
import toast from "react-hot-toast";

export default function Order(props) {
  const { mode } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    id,
    module: "",
    status: null,
    number: "",
    date: "",
    counts: {
      locations: 0,
      items: 0,
      expenses: 0,
      comments: 0,
    },
    amounts: {
      cost: 0,
      expense: 0,
      total: 0,
    },
  });

  const [invoices, setInvoices] = useState([]);
  const [showExpenses, setShowExpenses] = useState(false);

  const { refetch } = useQuery(GET_ORDER, {
    variables: { id },
    onCompleted: (data) => {
      if (data?.order?.success) {
        const { order } = data.order;
        setInfo({
          id: order?.id,
          module: order?.module,
          status: order?.status,
          number: order?.number,
          date: order?.date,
          counts: {
            locations: order?.counts?.locations,
            items: order?.counts?.items,
            expenses: order?.counts?.expenses,
            comments: order?.counts?.comments,
          },
          amounts: {
            cost: order?.amounts?.cost,
            expense: order?.amounts?.expense,
            total: order?.amounts?.total,
          },
        });

        setInvoices(order?.invoices);

        // manage expenses visibility
        if (order?.module === "PRODUCE") {
          if (order?.status === "COMPLETED") setShowExpenses(true);
          else if (order?.status === "PROCESSING") setShowExpenses(true);
          else setShowExpenses(false);
        } else setShowExpenses(false);

        // manage notify items visibility
        if (mode === "NOTIFY" && order.status !== "WAITING")
          navigate(`/order/${id}/summary`);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  return (
    <UserLayout title={`Order ${info.number}`}>
      <HeadSection heading={`${info.number}`}>
        <button
          className="btn btn-secondary btn-outline"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </HeadSection>
      <div className="flex flex-col gap-6 pt-6">
        {/* order summary */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
          <OrderStats info={info} />
        </section>
        {/* order invoices */}
        {mode === "SUMMARY" && invoices.length > 0 && (
          <>
            <OrderInvoicesList order={id} invoices={invoices} />
          </>
        )}
        {/* order SUMMARY */}
        {mode === "SUMMARY" && (
          <>
            {/* order items table */}
            <>
              <ItemsTable
                id={id}
                order={id}
                info={info}
                module={info?.module}
                status={info?.status}
                refreshOrder={refetch}
              />
            </>
            {/* order expenses */}
            {showExpenses && (
              <>
                <OrderExpensesList order={id} info={info} />
              </>
            )}
          </>
        )}
        {/* order NOTIFY */}
        {mode === "NOTIFY" && (
          <>
            <NotifyItemsList order={id} info={info} refreshOrder={refetch} />
          </>
        )}
        {/* order notes */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Comments</h2>
          <CommentsList entity={"Order"} reference={id} />
        </section>
      </div>
    </UserLayout>
  );
}
