import { useState, useEffect, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import { UPDATE_PRODUCT } from "utils/mutations";
import { LIST_PRODUCTS, GET_PRODUCT } from "utils/queries";
import { useMutation } from "@apollo/client";
import { myBucket } from "utils/helpers";
import clsx from "clsx";

export default function ProductThumbnailForm(props) {
  const { product } = props;

  const { id } = product;

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState("jpg");

  const editor = useRef(null);

  const [updateProductThumbnail] = useMutation(UPDATE_PRODUCT, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Product thumbnail updated!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: LIST_PRODUCTS },
      { query: GET_PRODUCT, variables: { id } },
    ],
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Key: `thumbnail/${id}.${fileExtension}`,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on("complete", () => {
        updateProductThumbnail({
          variables: {
            inputs: {
              id: id,
              thumbnail: `${id}.${fileExtension}`,
              module: product?.module,
              name: product?.name,
              unit: product?.unit,
              group: product?.group,
            },
          },
        });
      })
      .send((err) => {
        if (err) toast.error(err.message);
      });
  };

  const getImageUrl = async () => {
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();
    const file = new File([blob], `${id}.${fileExtension}`);

    return file;
  };

  useEffect(() => {
    if (product?.thumbnail) {
      setSelectedFile(product.thumbnail);
      var extension = product.thumbnail.substring(
        product.thumbnail.lastIndexOf(".") + 1
      );
      setFileExtension(extension);
    }
  }, [product]);

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label className="label">
          <span className="label-text font-semibold">Product Thumbnail</span>
        </label>
        <Dropzone
          onDrop={(dropped) => setSelectedFile(dropped[0])}
          noClick={true}
          noKeyboard
          maxFiles={1}
          style={{ width: "200px", height: "200px" }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                ref={editor}
                image={selectedFile}
                width={200}
                height={200}
                disableHiDPIScaling={true}
              />
              <input {...getInputProps()} />
              <p className="text-sm my-2 font-semibold">
                Drag 'n' drop an image here, or click to select files
              </p>
            </div>
          )}
        </Dropzone>
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleFileInput}
        />
        {progress > 0 ? <div>File Upload Progress is {progress}%</div> : null}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loader ? "loading" : null}`)}
            onClick={async () => {
              if (editor) {
                const image = await getImageUrl();
                uploadFile(image);
              }
            }}
          >
            Upload Thumbnail
          </button>
        </div>
      </form>
    </div>
  );
}
