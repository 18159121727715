import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrdersList from "components/lists/Orders";

export default function Home(props) {
  const navigate = useNavigate();

  return (
    <UserLayout title="Dashboard">
      <HeadSection heading="Dashboard">
        <Link to={`/create/order`} className="btn btn-secondary btn-outline">
          Open Order
        </Link>
      </HeadSection>
      <section className="flex flex-col gap-2 py-4">
        <h2 className="text-lg font-semibold mb-2 mx-2">
          Open <small className="font-medium">(orders with DRAFT status)</small>
        </h2>
        <OrdersList type="DRAFT" status={["DRAFT"]} />
      </section>
      <section className="flex flex-col gap-2 py-4">
        <h2 className="text-lg font-semibold mb-2 mx-2">
          Pending{" "}
          <small className="font-medium">(orders with WAITING status)</small>
        </h2>
        <OrdersList type="WAITING" status={["WAITING"]} />
      </section>
      <section className="flex flex-col gap-2 py-4">
        <h2 className="text-lg font-semibold mb-2 mx-2">
          In Progress{" "}
          <small className="font-medium">(orders with PROCESSING status)</small>
        </h2>
        <OrdersList type="PROCESSING" status={["PROCESSING"]} />
      </section>
      <section className="flex justify-center p-6">
        <button
          className="btn btn-md btn-secondary btn-ghost text-secondary"
          onClick={() => navigate("/orders")}
        >
          View All Orders
        </button>
      </section>
    </UserLayout>
  );
}
