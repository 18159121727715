import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password, role: null) {
      code
      message
      success
      token
    }
  }
`;

export const RESET = gql`
  mutation Reset($username: String!) {
    reset(username: $username, role: null) {
      code
      message
      success
      user {
        id
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($inputs: ProfileInput!) {
    updated: updateProfile(inputs: $inputs) {
      code
      message
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation UpdatePassword($password: String!) {
    updated: updatePassword(password: $password) {
      code
      message
      success
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($inputs: UserInput!) {
    created: createUser(inputs: $inputs) {
      code
      message
      success
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($inputs: UserInput!) {
    updated: updateUser(inputs: $inputs) {
      code
      message
      success
      user {
        id
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleted: deleteUser(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation($inputs: LocationInput!) {
    created: createLocation(inputs: $inputs) {
      code
      message
      success
      location {
        id
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($inputs: LocationInput!) {
    updated: updateLocation(inputs: $inputs) {
      code
      message
      success
      location {
        id
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleted: deleteLocation(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_OPERATOR = gql`
  mutation CreateOperator($inputs: OperatorInput!) {
    created: createOperator(inputs: $inputs) {
      code
      message
      success
      operator {
        id
      }
    }
  }
`;

export const UPDATE_OPERATOR = gql`
  mutation UpdateOperator($inputs: OperatorInput!) {
    updated: updateOperator(inputs: $inputs) {
      code
      message
      success
      operator {
        id
      }
    }
  }
`;

export const DELETE_OPERATOR = gql`
  mutation DeleteOperator($id: ID!) {
    deleted: deleteOperator(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($inputs: ScheduleInput!) {
    created: createSchedule(inputs: $inputs) {
      code
      message
      success
      schedule {
        id
      }
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($inputs: ScheduleInput!) {
    updated: updateSchedule(inputs: $inputs) {
      code
      message
      success
      schedule {
        id
      }
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation DeleteSchedule($id: ID!) {
    deleted: deleteSchedule(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($inputs: SupplierInput!) {
    created: createSupplier(inputs: $inputs) {
      code
      message
      success
      supplier {
        id
      }
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier($inputs: SupplierInput!) {
    updated: updateSupplier(inputs: $inputs) {
      code
      message
      success
      supplier {
        id
      }
    }
  }
`;

export const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID!) {
    deleted: deleteSupplier(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($inputs: productInput!) {
    created: createProduct(inputs: $inputs) {
      code
      message
      success
      product {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($inputs: productInput!) {
    updated: updateProduct(inputs: $inputs) {
      code
      success
      message
      product {
        id
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleted: deleteProduct(id: $id) {
      code
      message
      success
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($inputs: TransactionInput!) {
    created: createTransaction(inputs: $inputs) {
      code
      success
      message
      transaction {
        id
      }
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($inputs: TransactionInput!) {
    updated: updateTransaction(inputs: $inputs) {
      code
      success
      message
      transaction {
        id
      }
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($id: ID!) {
    deleted: deleteTransaction(id: $id) {
      code
      success
      message
    }
  }
`;

export const CREATE_STOCK = gql`
  mutation CreateStock($inputs: StockInput!) {
    created: createStock(inputs: $inputs) {
      code
      success
      message
      stock {
        id
      }
    }
  }
`;

export const UPDATE_STOCK = gql`
  mutation UpdateStock($inputs: StockInput!) {
    updated: updateStock(inputs: $inputs) {
      code
      success
      message
      stock {
        id
      }
    }
  }
`;

export const DELETE_STOCK = gql`
  mutation DeleteStock($id: ID!) {
    deleted: deleteStock(id: $id) {
      code
      success
      message
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($inputs: orderInput!) {
    created: createOrder(inputs: $inputs) {
      code
      success
      message
      order {
        id
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($inputs: orderInput!) {
    updated: updateOrder(inputs: $inputs) {
      code
      success
      message
      order {
        id
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($id: ID!) {
    deleted: deleteOrder(id: $id) {
      code
      message
      success
    }
  }
`;

export const REQUEST_ORDER_ITEM = gql`
  mutation RequestOrderItem($inputs: requestInput!) {
    updated: requestOrderItem(inputs: $inputs) {
      code
      message
      success
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation CreateComment($inputs: CommentInput!) {
    created: createComment(inputs: $inputs) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($inputs: CommentInput!) {
    updated: updateComment(inputs: $inputs) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleted: deleteComment(id: $id) {
      code
      success
      message
      comment {
        id
      }
    }
  }
`;

export const ADD_ORDER_ITEM = gql`
  mutation AddOrderItem($inputs: itemInput!) {
    created: createItem(inputs: $inputs) {
      code
      message
      success
      item {
        id
      }
    }
  }
`;

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateOrderItem($inputs: ItemInput!) {
    updated: updateItem(inputs: $inputs) {
      code
      success
      message
      item {
        id
      }
    }
  }
`;

export const REMOVE_ORDER_ITEM = gql`
  mutation RemoveOrderItem($id: ID!) {
    deleted: deleteItem(id: $id) {
      code
      message
      success
    }
  }
`;
