import { useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_SCHEDULES } from "utils/queries";
import { UPDATE_SCHEDULE, DELETE_SCHEDULE } from "utils/mutations";
import clsx from "clsx";
import { toAmPm, statusTagColor } from "utils/helpers";

export default function ScheduleTable() {
  const [schedule, setSchedule] = useState([]);

  useQuery(LIST_SCHEDULES, {
    onCompleted: (data) => {
      if (data?.schedules?.success) setSchedule(data?.schedules?.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    onCompleted: (data) => {
      if (!data?.updated?.success)
        toast.error("Schedule update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Schedule updated successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SCHEDULES }],
  });

  const [deleteSchedule] = useMutation(DELETE_SCHEDULE, {
    onCompleted: (data) => {
      if (!data?.deleted?.success)
        toast.error("Schedule deletion failed, please check inputs");
      if (data?.deleted?.success) {
        toast.success("Schedule deleted successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SCHEDULES }],
  });

  const handleSkip = ({ id, module, day, open, close }) => {
    updateSchedule({
      variables: {
        inputs: {
          id,
          module,
          day,
          open,
          close,
          status: "SKIPPED",
        },
      },
    });
  };

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this schedule?"))
      return;
    deleteSchedule({ variables: { id } });
  };

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <div className="flex flex-col md:flex-row justify-between p-2">
        <h1 className="subheading">Order Schedule</h1>
        <Link className="btn btn-secondary btn-outline" to="/create/schedule">
          Add schedule
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Module</th>
              <th className="text-xs">Day</th>
              <th className="text-xs">Open</th>
              <th className="text-xs">Close</th>
              <th className="text-xs text-center">Status</th>
              <th className="text-xs">
                <span className="pl-3">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {schedule && schedule.length > 0 ? (
              schedule.map((value, index) => (
                <tr key={index} className="text-left">
                  <td>{index + 1}</td>
                  <th>{value.module}</th>
                  <td>{value.day}</td>
                  <td>{toAmPm(value.open)}</td>
                  <td>{toAmPm(value.close)}</td>
                  <td className="text-center">
                    <span
                      className={clsx(
                        "text-xs py-1 px-2 rounded",
                        `${statusTagColor(value.status)}`
                      )}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td className="space-x-2">
                    <button
                      className={clsx(
                        "btn btn-sm btn-ghost",
                        `${value.status === "ACTIVE" ? null : "btn-disabled"}`
                      )}
                      onClick={() =>
                        handleSkip({
                          id: value.id,
                          module: value.module,
                          day: value.day,
                          open: value.open,
                          close: value.close,
                        })
                      }
                    >
                      Skip Next
                    </button>
                    <Link
                      className="btn btn-sm btn-ghost"
                      to={`/update/schedule/${value.id}`}
                    >
                      Update
                    </Link>
                    <button
                      className={clsx(
                        "btn btn-sm btn-ghost",
                        `${value.role === "ADMIN" ? "btn-disabled" : null}`
                      )}
                      onClick={() => handleDelete(value.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No schedules found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
