import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_ORDERS } from "utils/queries";
import { UPDATE_ORDER } from "utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";
import { upperFirst, formatISODate } from "utils/helpers";

export default function OrdersList(props) {
  const { type, status } = props;

  const [list, setList] = useState([]);

  const [frezzing, setFreezing] = useState("");
  const [processing, setProcessing] = useState("");
  const [unfreezing, setUnfreezing] = useState("");
  const [stopping, setStopping] = useState("");

  const navigate = useNavigate();

  const { startPolling, stopPolling } = useQuery(LIST_ORDERS, {
    variables: { status },
    onCompleted: (data) => {
      if (data?.orders?.success) {
        setList(data?.orders?.list);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onCompleted: (data) => {
      toast.success(data?.updated?.message);
      setFreezing("");
      setProcessing("");
      setUnfreezing("");
      setStopping("");
    },
    onError: (error) => {
      toast.error(error.message);
      setFreezing("");
      setProcessing("");
      setUnfreezing("");
      setStopping("");
    },
    refetchQueries: [{ query: LIST_ORDERS }],
  });

  const handleFreezeOrder = (id) => {
    setFreezing(id);
    updateOrder({
      variables: { inputs: { id, status: "WAITING" } },
      refetchQueries: [
        { query: LIST_ORDERS, variables: { status: ["WAITING"] } },
        { query: LIST_ORDERS, variables: { status: ["DRAFT"] } },
      ],
    });
  };

  const handleProcessOrder = (id) => {
    setProcessing(id);
    updateOrder({
      variables: { inputs: { id, status: "PROCESSING" } },
      refetchQueries: [
        { query: LIST_ORDERS, variables: { status: ["PROCESSING"] } },
        { query: LIST_ORDERS, variables: { status: ["WAITING"] } },
        { query: LIST_ORDERS, variables: { status: ["DRAFT"] } },
      ],
    });
  };

  const handleNotifyOrder = (id) => {
    navigate(`/order/${id}/notify`);
  };

  const handleUnfreezeOrder = (id) => {
    // back to DRAFT from READY
    setUnfreezing(id);
    const addOneHour = new Date(new Date().getTime() + 60 * 60 * 1000); // add 1 hour to avoid auto freeze
    updateOrder({
      variables: { inputs: { id, status: "DRAFT", close: addOneHour } },
      refetchQueries: [
        { query: LIST_ORDERS, variables: { status: ["WAITING"] } },
        { query: LIST_ORDERS, variables: { status: ["DRAFT"] } },
        { query: LIST_ORDERS, variables: { status: ["PROCESSING"] } },
      ],
    });
  };

  const handleStopOrder = (id) => {
    // back to READY from PROCESSING
    setStopping(id);
    updateOrder({
      variables: { inputs: { id, status: "WAITING" } },
      refetchQueries: [
        { query: LIST_ORDERS, variables: { status: ["PROCESSING"] } },
        { query: LIST_ORDERS, variables: { status: ["WAITING"] } },
      ],
    });
  };

  useEffect(() => {
    startPolling(15000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return (
    <div className="rounded bg-base-200 p-4">
      <ul className="w-full divide-y-2">
        {list.length > 0 ? (
          list.map((item, index) => {
            switch (type) {
              case "DRAFT":
                return (
                  <DraftOrderListItem
                    key={item.id}
                    item={item}
                    freeze={handleFreezeOrder}
                    frezzing={frezzing}
                    process={handleProcessOrder}
                    processing={processing}
                  />
                );
              case "WAITING":
                return (
                  <WaitingOrderListItem
                    key={item.id}
                    item={item}
                    process={handleProcessOrder}
                    processing={processing}
                    notify={handleNotifyOrder}
                    unfreeze={handleUnfreezeOrder}
                    unfreezing={unfreezing}
                  />
                );
              case "PROCESSING":
                return (
                  <ProcessingOrderListItem
                    key={item.id}
                    item={item}
                    stop={handleStopOrder}
                    stopping={stopping}
                    unfreeze={handleUnfreezeOrder}
                    unfreezing={unfreezing}
                  />
                );
              default:
                return <></>;
            }
          })
        ) : (
          <li className="flex flex-row justify-center gap-4 p-2 py-4">
            <span className="text-gray-500">No orders found</span>
          </li>
        )}
      </ul>
    </div>
  );
}

function DraftOrderListItem(props) {
  const { item, freeze, frezzing, process, processing } = props;

  const { id, number, date, module, close } = item;

  return (
    <li className="flex flex-col md:flex-row md:items-center gap-4 p-2 py-4">
      <div className="flex-1">
        <div className="flex flex-col md:flex-row justify-between w-full md:p-2">
          <div>
            <span className="font-bold"># {number}</span> |{" "}
            <span className="tracking-wider">
              {formatISODate(date, "DD/MM/YYYY")}
            </span>
          </div>
          <div>
            <span className="font-bold">
              {upperFirst(module.toLowerCase())}
            </span>{" "}
            <span className="text-gray-500 text-sm">
              (auto freeze at {formatISODate(close, "hh:mm A")})
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {module !== "FOOD" ? (
          <button
            className={clsx(
              "btn btn-xs btn-outline btn-primary",
              frezzing === id && "loading"
            )}
            onClick={() => freeze(id)}
          >
            Freeze Order
          </button>
        ) : (
          <button
            className={clsx(
              "btn btn-xs btn-outline btn-primary",
              processing === id && "loading"
            )}
            onClick={() => process(id)}
          >
            Process Order
          </button>
        )}
      </div>
    </li>
  );
}

function WaitingOrderListItem(props) {
  const { item, process, processing, notify, unfreeze, unfreezing } = props;

  const { id, number, date, module } = item;

  return (
    <li className="flex flex-col md:flex-row md:items-center gap-4 p-2 py-4">
      <div className="flex-1">
        <div className="flex flex-col md:flex-row justify-between w-full md:p-2">
          <div>
            <span className="font-bold"># {number}</span> |{" "}
            <span className="tracking-wider">
              {formatISODate(date, "DD/MM/YYYY")}
            </span>
          </div>
          <div>
            <span className="font-bold">
              {upperFirst(module.toLowerCase())}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2 flex-wrap">
        <button
          className={clsx(
            "btn btn-xs btn-outline btn-accent",
            unfreezing && "loading"
          )}
          onClick={() => unfreeze(id)}
        >
          Revert to Draft
        </button>
        <button
          className={clsx(
            "btn btn-xs btn-outline btn-primary",
            processing && "loading"
          )}
          onClick={() => process(id)}
        >
          Process Order
        </button>
        {module === "PRODUCE" && (
          <button
            className="btn btn-xs btn-outline btn-secondary"
            onClick={() => notify(id)}
          >
            Notify Suppliers
          </button>
        )}
      </div>
    </li>
  );
}

function ProcessingOrderListItem(props) {
  const { item, stop, stopping, unfreeze, unfreezing } = props;

  const { id, number, date, module } = item;

  return (
    <li className="flex flex-col md:flex-row md:items-center gap-4 p-2 py-4">
      <div className="flex-1">
        <div className="flex flex-col md:flex-row justify-between w-full md:p-2">
          <div>
            <span className="font-bold"># {number}</span> |{" "}
            <span className="tracking-wider">
              {formatISODate(date, "DD/MM/YYYY")}
            </span>
          </div>
          <div>
            <span className="font-bold">
              {upperFirst(module.toLowerCase())}
            </span>{" "}
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {module !== "FOOD" ? (
          <button
            className={clsx(
              "btn btn-xs btn-outline btn-accent",
              stopping === id && "loading"
            )}
            onClick={() => stop(id)}
          >
            Revert to Waiting
          </button>
        ) : (
          <button
            className={clsx(
              "btn btn-xs btn-outline btn-accent",
              unfreezing === id && "loading"
            )}
            onClick={() => unfreeze(id)}
          >
            Revert to Draft
          </button>
        )}
      </div>
    </li>
  );
}
