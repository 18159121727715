import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT } from "utils/mutations";
import { LIST_SUPPLIERS, GET_PRODUCT } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function ProductSuppliersList(props) {
  const { id, list, module } = props;

  const [removeProductSupplier, { loading: removing }] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted: (data) => {
        if (!data?.updated?.success) toast.error("Supplier can not be removed");
        if (data?.updated?.success) {
          toast.success("Supplier removed successfully");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries: [
        { query: GET_PRODUCT, variables: { id } },
        { query: LIST_SUPPLIERS, variables: { module } },
      ],
    }
  );

  const handleRemove = (supplierId) => {
    if (!window.confirm("Are you sure you want to remove this supplier?"))
      return;
    removeProductSupplier({
      variables: {
        inputs: {
          id,
          removeSupplier: supplierId,
        },
      },
    });
  };

  return (
    <div>
      <label htmlFor="supplier" className="label">
        <span className="label-text font-semibold">Product Suppliers</span>
      </label>
      <ul className="w-full divide-y-2">
        {list.length > 0
          ? list.map((value, index) => {
              return (
                <li key={index} className="flex flex-row items-center gap-4">
                  <div className="flex-1">
                    <div className="flex flex-row justify-between w-full p-2">
                      <span>
                        {value.supplier?.name}{" "}
                        {value.supplier.availability && (
                          <small className="font-xs font-light">
                            ({value.supplier.availability})
                          </small>
                        )}
                      </span>
                      {value.percentage ? (
                        <span>{value.percentage}%</span>
                      ) : null}
                    </div>
                  </div>
                  <button
                    className={clsx(
                      "btn btn-xs btn-outline btn-secondary",
                      removing && "loading"
                    )}
                    onClick={() => handleRemove(value.supplier?.id)}
                  >
                    Remove
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
}
