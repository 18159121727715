import { useEffect, useState } from "react";
import clsx from "clsx";
import { statusTagColor, currency, formatISODate } from "utils/helpers";

export default function OrderStats(props) {
  const { info } = props;

  const [extended, setExtended] = useState(false);

  useEffect(() => {
    if (info?.status !== "DRAFT") {
      if (info?.module !== "GROCERY") setExtended(true);
      else setExtended(false);
    } else setExtended(false);
  }, [info]);

  return (
    <div className="sticky top-4 flex flex-col gap-4 bg-white rounded">
      <div className="flex flex-col md:flex-row md:justify-between divide-y md:divide-y-0">
        {/* Module */}
        <div
          className={clsx(
            "min-w-fit md:text-center md:border-r px-4 py-2 md:py-4",
            extended ? "basis-1/6" : "basis-1/5"
          )}
        >
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Module:
            </span>
            <span className="font-bold">{info?.module}</span>
          </p>
        </div>
        {/* Status */}
        <div
          className={clsx(
            "min-w-fit md:text-center md:border-r px-4 py-2 md:py-4",
            extended ? "basis-1/6" : "basis-1/5"
          )}
        >
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Status:
            </span>
            <span
              className={clsx(
                "text-xs py-1 px-2 rounded",
                `${statusTagColor(info?.status)}`
              )}
            >
              {info?.status}
            </span>
          </p>
        </div>
        {/* Date */}
        <div
          className={clsx(
            "min-w-fit md:text-center md:border-r px-4 py-2 md:py-4",
            extended ? "basis-1/6" : "basis-1/5"
          )}
        >
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Date:
            </span>
            <span className="font-bold">
              {formatISODate(info?.date, "DD/MM/YYYY")}
            </span>
          </p>
        </div>
        {/* Locations Count */}
        <div
          className={clsx(
            "min-w-fit md:text-center md:border-r px-4 py-2 md:py-4",
            extended ? "basis-1/6" : "basis-1/5"
          )}
        >
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Locations:
            </span>
            <span className="font-bold">{info?.counts?.locations}</span>
          </p>
        </div>
        {/* Items Count */}
        <div
          className={clsx(
            "min-w-fit md:text-center px-4 py-2 md:py-4",
            extended ? "basis-1/6 md:border-r" : "basis-1/5"
          )}
        >
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Items:
            </span>
            <span className="font-bold">{info?.counts?.items ?? 0}</span>
          </p>
        </div>
        {/* Order Total */}
        {extended && (
          <div className="basis-1/6 min-w-fit md:text-center px-4 py-2 md:py-4">
            <p className="space-x-2 md:space-x-1">
              <span className="label md:pt-1 text-gray-500 inline-block md:block">
                Total:
              </span>
              <span className="font-bold">
                {info?.amounts?.total ? currency(info.amounts.total) : `$0.00`}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
