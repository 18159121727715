import { Link } from "react-router-dom";
import PublicLayout from "layouts/Public";

export default function Error(props) {
  const { title = "404", message = "Page not found!" } = props;

  return (
    <PublicLayout title={title}>
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">{title}</h1>
        <div className="text-center pt-4">{message}</div>
      </div>
      <div className="text-center pt-6">
        <Link to="/login" className="text-secondary">
          Back to login
        </Link>
      </div>
    </PublicLayout>
  );
}
