import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_OPERATOR, UPDATE_OPERATOR } from "utils/mutations";
import { GET_OPERATOR, LIST_OPERATORS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function OperatorForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [createOperator] = useMutation(CREATE_OPERATOR, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.created?.success)
        toast.error("Operator creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Operator created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_OPERATORS }],
  });

  const [updateOperator] = useMutation(UPDATE_OPERATOR, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.updated?.success)
        toast.error("Operator update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Operator updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_OPERATORS }],
  });

  const [getOperator] = useLazyQuery(GET_OPERATOR, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.operator?.success) {
        const { operator } = data.operator;
        setValue("operator_name", operator.name);
        setValue("operator_username", operator.username);
        setValue("operator_email", operator.email);
        setValue("operator_module", operator.module);
        setValue("operator_permissions", operator.permissions);
        setValue("operator_status", operator.status);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const onSubmit = (data) => {
    setLoading(true);

    if (mode === "edit") {
      updateOperator({
        variables: {
          inputs: {
            id,
            name: data.operator_name,
            username: data.operator_username,
            email: data.operator_email,
            module: data.operator_module,
            permissions: data.operator_permissions,
            status: data.operator_status,
          },
        },
      });
    } else {
      createOperator({
        variables: {
          inputs: {
            name: data.operator_name,
            username: data.operator_username,
            email: data.operator_email,
            module: data.operator_module,
            permissions: data.operator_permissions,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getOperator({ variables: { id } });
    }
  }, [getOperator, id, mode]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Operator name"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("operator_name", {
              required: "Name is required",
            })}
          />
          {errors?.operator_name && (
            <div className="mt-1 text-red-600">
              <small>{errors?.operator_name?.message}</small>
            </div>
          )}
        </div>
        {/* username */}
        <div>
          <label htmlFor="username" className="label">
            <span className="label-text font-semibold">Username</span>
          </label>
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("operator_username", {
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
            })}
          />
          {errors?.operator_username && (
            <div className="mt-1 text-red-600">
              <small>{errors?.operator_username?.message}</small>
            </div>
          )}
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Operator email"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("operator_email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email",
              },
            })}
          />
          {errors?.operator_email && (
            <div className="mt-1 text-red-600">
              <small>{errors?.operator_email?.message}</small>
            </div>
          )}
        </div>
        {/* module */}
        <div>
          <label htmlFor="module" className="label">
            <span className="label-text font-semibold">Module</span>
          </label>
          <select
            name="module"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("operator_module", {
              required: "Module is required",
            })}
          >
            <option value="PRODUCE">Produce</option>
          </select>
          {errors?.operator_module && (
            <div className="mt-1 text-red-600">
              <small>{errors?.operator_module?.message}</small>
            </div>
          )}
        </div>
        {/* permissions */}
        <div>
          <label htmlFor="permissions" className="label">
            <span className="label-text font-semibold">Permissions</span>
          </label>
          <select
            name="permissions"
            className="input input-bordered w-full bg-base-200 h-32 space-y-2"
            disabled={loading}
            multiple={true}
            {...register("operator_permissions", {
              required: "Permissions is required",
            })}
          >
            <option value="COLLECT">Collect</option>
            <option value="DISTRIBUTE">Distribute</option>
            <option value="PAYMENTS">Payments</option>
            <option value="EXPENSE">Add Expense</option>
            <option value="PRODUCT">Add Product</option>
            <option value="COMPLETE">Complete Order</option>
          </select>
          <label className="label">
            <span className="label-text-alt">
              Limit feature access for operator
            </span>
          </label>
          {errors?.operator_permissions && (
            <div className="mt-1 text-red-600">
              <small>{errors?.operator_permissions?.message}</small>
            </div>
          )}
        </div>
        {/* status */}
        {mode === "edit" ? (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("operator_status", {
                required: "Status is required",
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            {errors?.operator_status && (
              <div className="mt-1 text-red-600">
                <small>{errors?.operator_status?.message}</small>
              </div>
            )}
          </div>
        ) : null}
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update operator" : "Create operator"}
          </button>
        </div>
      </form>
    </div>
  );
}
