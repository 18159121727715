import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_SCHEDULE, UPDATE_SCHEDULE } from "utils/mutations";
import { GET_SCHEDULE, LIST_SCHEDULES } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function ScheduleForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [createSchedule] = useMutation(CREATE_SCHEDULE, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.created?.success)
        toast.error("Schedule creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Schedule created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SCHEDULES }],
  });

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.updated?.success)
        toast.error("Schedule update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Schedule updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SCHEDULES }],
  });

  const [getSchedule] = useLazyQuery(GET_SCHEDULE, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.schedule?.success) {
        const { schedule } = data.schedule;
        setValue("schedule_module", schedule?.module);
        setValue("schedule_day", schedule?.day);
        setValue("schedule_open_time", schedule?.open);
        setValue("schedule_close_time", schedule?.close);
        setValue("schedule_status", schedule?.status);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (mode === "edit") {
      updateSchedule({
        variables: {
          inputs: {
            id,
            module: data.schedule_module,
            day: data.schedule_day,
            open: data.schedule_open_time,
            close: data.schedule_close_time,
            status: data.schedule_status,
          },
        },
      });
    } else {
      createSchedule({
        variables: {
          inputs: {
            module: data.schedule_module,
            day: data.schedule_day,
            open: data.schedule_open_time,
            close: data.schedule_close_time,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getSchedule({ variables: { id } });
    }
  }, [mode, id, getSchedule]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* module */}
        <div>
          <label htmlFor="type" className="label">
            <span className="label-text font-semibold">Module</span>
          </label>
          <select
            name="type"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("schedule_module", {
              required: "Module is required",
            })}
          >
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD">Food</option>
          </select>
          {errors?.schedule_module && (
            <div className="mt-1 text-red-600">
              <small>{errors?.schedule_module?.message}</small>
            </div>
          )}
        </div>
        {/* day */}
        <div>
          <label htmlFor="day" className="label">
            <span className="label-text font-semibold">Day</span>
          </label>
          <select
            name="day"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("schedule_day", {
              required: "Day is required",
            })}
          >
            <option value="MONDAY">Monday</option>
            <option value="TUESDAY">Tuesday</option>
            <option value="WEDNESDAY">Wednesday</option>
            <option value="THURSDAY">Thursday</option>
            <option value="FRIDAY">Friday</option>
            <option value="SATURDAY">Saturday</option>
            <option value="SUNDAY">Sunday</option>
          </select>
          {errors?.schedule_day && (
            <div className="mt-1 text-red-600">
              <small>{errors?.schedule_day?.message}</small>
            </div>
          )}
        </div>
        {/* open time */}
        <div>
          <label htmlFor="open_time" className="label">
            <span className="label-text font-semibold">Open Time</span>
          </label>
          <input
            type="time"
            name="open_time"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("schedule_open_time", {
              required: "Open time is required",
            })}
          />
          {errors?.schedule_open_time && (
            <div className="mt-1 text-red-600">
              <small>{errors?.schedule_open_time?.message}</small>
            </div>
          )}
        </div>
        {/* close time */}
        <div>
          <label htmlFor="close_time" className="label">
            <span className="label-text font-semibold">Close Time</span>
          </label>
          <input
            type="time"
            name="close_time"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("schedule_close_time", {
              required: "Close time is required",
            })}
          />
          {errors?.schedule_close_time && (
            <div className="mt-1 text-red-600">
              <small>{errors?.schedule_close_time?.message}</small>
            </div>
          )}
        </div>
        {/* status */}
        {mode === "edit" ? (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("schedule_status", {
                required: "Status is required",
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="SKIP" disabled>
                Skip
              </option>
            </select>
            {errors?.schedule_status && (
              <div className="mt-1 text-red-600">
                <small>{errors?.schedule_status?.message}</small>
              </div>
            )}
          </div>
        ) : null}
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update schedule" : "Create schedule"}
          </button>
        </div>
      </form>
    </div>
  );
}
