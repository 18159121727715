import { useState, useEffect, useContext } from "react";
import { AuthContext } from "contexts/Auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_TRANSACTION, UPDATE_TRANSACTION } from "utils/mutations";
import {
  TRANSACTION_CONSTANTS,
  GET_TRANSACTION,
  LIST_TRANSACTIONS,
} from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import {
  uploadFile,
  upperFirst,
  formatISODate,
  receiptUri,
} from "utils/helpers";

export default function TransactionForm(props) {
  const { id } = props;

  const { user } = useContext(AuthContext);

  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const [modules, setModules] = useState([]);
  const [locations, setLocations] = useState([]);
  const [operators, setOperators] = useState([]);
  const [categories, setCategories] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm({ mode: "onTouched" });

  const { transaction_entity_type, transaction_category } = watch();

  const { loading: initialising } = useQuery(TRANSACTION_CONSTANTS, {
    onCompleted: (data) => {
      setModules(data?.modules);
      if (data?.modules?.length > 0)
        setValue("transaction_module", data.modules[0].value);
      setLocations(data?.locations?.locations);
      setOperators(data?.operators?.operators);
      setCategories(data?.categories);
      if (mode === "edit") getTransaction({ variables: { id } });
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: "PRODUCE",
      feature: "TRANSACTION",
      role: user?.data?.role,
    },
  });

  const [createTransaction, { loading: creating }] = useMutation(
    CREATE_TRANSACTION,
    {
      onCompleted: (data) => {
        if (!data?.created?.success)
          toast.error("Transaction creation failed, please check inputs");
        if (data?.created?.success) {
          toast.success("Transaction created successfully");
          reset();
          navigate(-1);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries: [
        {
          query: LIST_TRANSACTIONS,
          variables: { module: "PRODUCE" },
        },
      ],
    }
  );

  const [updateTransaction, { loading: updating }] = useMutation(
    UPDATE_TRANSACTION,
    {
      onCompleted: (data) => {
        if (!data?.updated?.success)
          toast.error("Transaction update failed, please check inputs");
        if (data?.updated?.success) {
          toast.success("Transaction updated successfully");
          reset();
          navigate(-1);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      refetchQueries: [
        {
          query: LIST_TRANSACTIONS,
          variables: { module: "PRODUCE" },
        },
        { query: GET_TRANSACTION, variables: { id: id } },
      ],
    }
  );

  const [getTransaction, { loading: fetching }] = useLazyQuery(
    GET_TRANSACTION,
    {
      onCompleted: (data) => {
        if (data?.transaction?.category === "CHARGE") navigate("/statement");
        if (data?.transaction?.success) {
          const { transaction } = data.transaction;
          setValue("transaction_module", transaction.module);
          setValue(
            "transaction_date",
            formatISODate(transaction.date, "YYYY-MM-DD")
          );
          setValue("transaction_entity_type", transaction.entity.type);
          if (transaction.entity.type === "Location")
            setValue("transaction_location", transaction.entity.id);
          if (transaction.entity.type === "Operator")
            setValue("transaction_operator", transaction.entity.id);
          setValue("transaction_category", transaction.category);
          setValue("transaction_type", transaction.type);
          setValue("transaction_amount", transaction.amount / 100);
          setValue("transaction_description", transaction.description);
          setImgSrc(transaction.receipt);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const handleCategoryChange = (e) => {
    const index = e.target.selectedIndex;

    const type = e.target[index].getAttribute("data-type");
    const entity = e.target[index].getAttribute("data-entity");
    const description = e.target[index].getAttribute("data-description");

    setValue("transaction_type", type);
    setValue("transaction_entity_type", entity);
    if (mode === "create") setValue("transaction_description", description);
  };

  const onSubmit = (data) => {
    const payload = {
      module: data.transaction_module,
      date: data.transaction_date,
      type: data.transaction_type,
      category: data.transaction_category,
      description: data.transaction_description,
      amount: parseInt(parseFloat(data.transaction_amount).toFixed(2) * 100),
      entity: data.transaction_entity_type,
      reference:
        data.transaction_entity_type === "Location"
          ? data.transaction_location
          : data.transaction_operator,
    };

    const inputs = mode === "edit" ? { id, ...payload } : { ...payload };

    if (data.transaction_receipt.length > 0) {
      uploadFile(data.transaction_receipt[0], "receipt").then((res) => {
        if (res.Key) inputs.receipt = res.Key;

        if (mode === "edit") updateTransaction({ variables: { inputs } });
        else createTransaction({ variables: { inputs } });
      });
    } else {
      inputs.receipt = undefined;

      if (mode === "edit") updateTransaction({ variables: { inputs } });
      else createTransaction({ variables: { inputs } });
    }
  };

  useEffect(() => {
    if (initialising || fetching || creating || updating) setLoading(true);
    if (!initialising && !fetching && !creating && !updating) setLoading(false);
  }, [initialising, fetching, creating, updating, loading]);

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="max-w-md">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* module */}
          <div>
            <label htmlFor="module" className="label">
              <span className="label-text font-semibold">Module</span>
            </label>
            <select
              name="module"
              className="input input-bordered w-full bg-base-200"
              disabled
              {...register("transaction_module", {
                required: "Module is required",
              })}
            >
              <option value={""} disabled>
                Select module
              </option>
              {modules.map((module, index) => (
                <option key={index} value={module.value}>
                  {module.label}
                </option>
              ))}
            </select>
            {errors?.transaction_module && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_module?.message}</small>
              </div>
            )}
          </div>
          {/* date */}
          <div>
            <label htmlFor="date" className="label">
              <span className="label-text font-semibold">Date</span>
            </label>
            <input
              type="date"
              name="date"
              max={formatISODate(tomorrow, "YYYY-MM-DD")}
              className="input input-bordered w-full bg-base-200"
              disabled={loading || mode === "edit"}
              {...register("transaction_date", {
                required: "Date is required",
                max: {
                  value: formatISODate(tomorrow, "YYYY-MM-DD"),
                  message: "Date cannot be in the future",
                },
              })}
            />
            {errors?.transaction_date && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_date?.message}</small>
              </div>
            )}
          </div>
          {/* categories */}
          <div>
            <label htmlFor="category" className="label">
              <span className="label-text font-semibold">Category</span>
            </label>
            <select
              name="category"
              className="input input-bordered w-full bg-base-200"
              disabled={loading || mode === "edit"}
              {...register("transaction_category", {
                required: "Category is required",
                onChange: handleCategoryChange,
              })}
            >
              <option value={""}>Select category</option>
              {categories.map((category, index) => (
                <option
                  key={index}
                  value={category.value}
                  data-type={category.type}
                  data-entity={category.entity}
                  data-description={category.description}
                >
                  {upperFirst(category.label)}
                </option>
              ))}
            </select>
            {errors?.transaction_category && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_category?.message}</small>
              </div>
            )}
          </div>
          {/* entity_type */}
          <div>
            <label htmlFor="entity" className="label">
              <span className="label-text font-semibold">Type</span>
            </label>
            <select
              name="entity"
              className="input input-bordered w-full bg-base-200"
              disabled={transaction_category !== "ADJUSTMENT" || loading}
              {...register("transaction_entity_type", {
                required: "Entity is required",
              })}
            >
              <option value={""}>Select entity</option>
              <option value="Location">Location</option>
              <option value="Operator">Operator</option>
            </select>
            {errors?.transaction_entity_type && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_entity_type?.message}</small>
              </div>
            )}
          </div>
          {/* locations */}
          {transaction_entity_type === "Location" && (
            <div>
              <label htmlFor="location" className="label">
                <span className="label-text font-semibold">Location</span>
              </label>
              <select
                name="location"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("transaction_location", {
                  required: "Location is required",
                })}
              >
                <option value="">Select location</option>
                {locations.map((location, index) => (
                  <option key={index} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
              {errors?.transaction_location && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.transaction_location?.message}</small>
                </div>
              )}
            </div>
          )}
          {/* operators */}
          {transaction_entity_type === "Operator" && (
            <div>
              <label htmlFor="operator" className="label">
                <span className="label-text font-semibold">Operator</span>
              </label>
              <select
                name="operator"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("transaction_operator", {
                  required: "Operator is required",
                })}
              >
                <option value="">Select operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator.id}>
                    {operator.name}
                  </option>
                ))}
              </select>
              {errors?.transaction_operator && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.transaction_operator?.message}</small>
                </div>
              )}
            </div>
          )}
          {/* type */}
          <div>
            <label htmlFor="type" className="label">
              <span className="label-text font-semibold">Credit / Debit</span>
            </label>
            <select
              name="type"
              className="input input-bordered w-full bg-base-200"
              disabled={transaction_category !== "ADJUSTMENT" || loading}
              {...register("transaction_type", {
                required: "Type is required",
              })}
            >
              <option value={""}>Select type</option>
              <option value="CREDIT">Credit</option>
              <option value="DEBIT">Debit</option>
            </select>
            {errors?.transaction_type && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_type?.message}</small>
              </div>
            )}
          </div>
          {/* amount */}
          <div>
            <label htmlFor="amount" className="label">
              <span className="label-text font-semibold">Amount</span>
            </label>
            <input
              type="number"
              name="amount"
              step={0.01}
              min={0.01}
              placeholder="0.00"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("transaction_amount", {
                required: "Amount is required",
                min: {
                  value: 0.01,
                  message: "Amount must be greater than 0",
                },
              })}
            />
            {errors?.transaction_amount && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_amount?.message}</small>
              </div>
            )}
          </div>
          {/* description */}
          <div>
            <label htmlFor="description" className="label">
              <span className="label-text font-semibold">Description</span>
            </label>
            <input
              type="text"
              name="description"
              rows={5}
              placeholder="Description"
              defaultValue={""}
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("transaction_description", {
                required: "Description is required",
              })}
            />
            {errors?.transaction_description && (
              <div className="text-red-600">
                <small>{errors?.transaction_description?.message}</small>
              </div>
            )}
          </div>
          {/* receipt */}
          <div>
            <label htmlFor="receipt" className="label">
              <span className="label-text font-semibold">Upload receipt</span>
            </label>
            <input
              type="file"
              name="receipt"
              className="input input-bordered bg-base-200 py-1 w-full file:mr-4 file:py-2 file:px-2 file:rounded file:text-sm file:font-semibold file:bg-base-200 hover:file:cursor-pointer file:border-none"
              disabled={loading}
              {...register("transaction_receipt")}
            />
            {errors?.transaction_receipt && (
              <div className="mt-1 text-red-600">
                <small>{errors?.transaction_receipt?.message}</small>
              </div>
            )}
          </div>
          {/* submit */}
          <div className="pt-4">
            <button
              className={clsx("btn btn-primary", loading && "loading")}
              disabled={!isValid || loading}
            >
              {mode === "edit" ? "Update transaction" : "Create transaction"}
            </button>
          </div>
        </form>
      </div>
      {imgSrc && (
        <div className="flex-1 h-7 w-full">
          <label htmlFor="module" className="label">
            <span className="label-text font-semibold">Receipt Preview</span>
          </label>
          <img src={receiptUri(imgSrc)} alt="receipt" className="md:max-w-sm" />
        </div>
      )}
    </div>
  );
}
