import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_ORDER_ITEMS } from "utils/queries";
import { REMOVE_ORDER_ITEM } from "utils/mutations";
import toast from "react-hot-toast";
import ItemsStats from "components/stats/Items";
import { LoadingTable } from "layouts/Loading";
import Drawer from "layouts/Drawer";
import CommentsList from "components/lists/Comments";
import { currency, unit, formatISODate } from "utils/helpers";
import {
  ArrowPathIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import { produceInvoice } from "utils/pdf";

export default function ItemsTable(props) {
  const { order, info, refreshOrder } = props;

  const { module, status, number, date, counts, amounts } = info;

  const [summary, setSummary] = useState({
    requests: 0,
    deliveries: 0,
  });
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [openComments, setOpenComments] = useState(false);
  const [commentItem, setCommentItem] = useState({
    id: null,
    name: "",
  });

  const [debouncedName] = useDebounce(name, 1000);

  const { loading, refetch } = useQuery(LIST_ORDER_ITEMS, {
    variables: {
      order,
      location: !!location ? location : undefined,
      name: !!debouncedName ? debouncedName : undefined,
    },
    onCompleted: (data) => {
      if (data.items.success) {
        setItems(data.items?.list);
        setLocations(data.items?.options?.locations);
        setSummary({
          requests: data.items?.summary?.requests ?? 0,
          deliveries: data.items?.summary?.deliveries ?? 0,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [removeItem] = useMutation(REMOVE_ORDER_ITEM, {
    onCompleted: (data) => {
      if (data.deleted.success) {
        toast.success(data.deleted.message);
        refetch();
        refreshOrder();
      } else {
        toast.error(data.deleted.message);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDelete = (id) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this item from the order?"
      )
    )
      return;
    removeItem({ variables: { id } });
  };

  const handlePrint = (type) => {
    const name = !!location
      ? locations.find((loc) => loc.id === location).label
      : "All Locations";

    const expense = !!location
      ? amounts.expense / counts.locations
      : amounts.expense;

    const payload = {
      number,
      date: formatISODate(date, "DD/MM/YYYY"),
      name,
      items,
      expense,
    };

    if (type === "invoice") produceInvoice(payload);
  };

  return (
    <section>
      <div className="flex flex-wrap flex-col md:flex-row md:justify-between md:items-baseline mb-4">
        <h2 className="text-lg font-semibold mb-2 mx-2">Items</h2>
        {/* action buttons */}
        <div className="flex gap-2">
          {module === "PRODUCE" && status === "COMPLETED" && (
            <button
              className="btn btn-sm btn-outline"
              onClick={() => handlePrint("invoice")}
            >
              Print Invoice
            </button>
          )}
        </div>
      </div>
      <div>
        <ItemsStats module={module} summary={summary} />
      </div>
      <div className="flex flex-col gap-4 bg-white rounded p-4">
        <div className="flex flex-col gap-2">
          {/* filters */}
          <div className="flex flex-col sm:flex-row justify-between p-2 gap-4">
            <div>
              <input
                className="input input-bordered w-full sm:w-80 bg-white form-control"
                type="search"
                inputMode="search"
                placeholder="Filter by item name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <div className="flex flex-row gap-2 items-center justify-end">
                <select
                  className="input input-bordered w-full sm:w-60 bg-white"
                  name="location"
                  value={location}
                  disabled={loading}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="">All</option>
                  {locations?.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.label}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-sm btn-ghost"
                  onClick={() => refetch()}
                >
                  <ArrowPathIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="table-normal w-full divide-y-2">
              <thead>
                <tr className="text-left uppercase">
                  <th className="text-xs">#</th>
                  <th className="text-xs">Name</th>
                  <th className="text-xs text-center">Requested</th>
                  {(module === "PRODUCE" || module === "GROCERY") && (
                    <th className="text-xs text-center">Notified</th>
                  )}
                  {module === "PRODUCE" && (
                    <th className="text-xs text-center">Collected</th>
                  )}
                  <th className="text-xs text-center">Delivered</th>
                  {(module === "PRODUCE" || module === "FOOD") && (
                    <>
                      <th className="text-xs text-center">Avg.</th>
                      <th className="text-xs text-center">Total</th>
                    </>
                  )}
                  {(status === "DRAFT" || status === "WAITING") && <th></th>}
                </tr>
              </thead>
              {loading ? (
                <LoadingTable rows={5} cols={7} className="h-10" />
              ) : (
                <tbody className="divide-y-2">
                  {items && items.length > 0 ? (
                    items.map((value, index) => (
                      <tr key={index} className="text-left">
                        <td>{index + 1}</td>
                        <td className="flex flex-row items-center rounded min-w-max">
                          <div className="relative z-10 flex-shrink-0 w-16">
                            <img
                              src={value.thumbnail}
                              className="min-w-16 min-h-16 w-16 h-16 object-cover rounded"
                              alt={value.name}
                            />
                            {value.highlighted && (
                              <span className="absolute -top-1 -left-1 flex h-2 w-2">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
                              </span>
                            )}
                          </div>
                          <div className="flex-1 flex-shrink-0 p-4">
                            <h3 className="text-lg font-semibold tracking-wider">
                              {value.name}{" "}
                            </h3>
                            <span className="text-sm font-semibold">
                              {value.group}
                            </span>{" "}
                            -{" "}
                            <span className="text-sm font-semibold">
                              {value.unit}
                            </span>
                          </div>
                          <div>
                            <button
                              className="bg-gray-100 p-1.5 rounded flex items-center gap-1"
                              onClick={() => {
                                setCommentItem({
                                  id: value.id,
                                  name: value.name,
                                });
                                setOpenComments(true);
                              }}
                            >
                              <span>
                                <ChatBubbleLeftEllipsisIcon className="w-4 h-4" />
                              </span>
                              <span className="font-mono text-xs">
                                {value.counts?.comments ?? 0}
                              </span>
                            </button>
                          </div>
                        </td>
                        {/* requested */}
                        <td className="space-x-2 min-w-max">
                          <div className="min-w-max text-center">
                            <span className="text-sm font-semibold">
                              {unit(value?.quantities?.requested ?? 0)}
                            </span>
                          </div>
                        </td>
                        {/* notified */}
                        {(module === "PRODUCE" || module === "GROCERY") && (
                          <td className="space-x-2 min-w-max">
                            <div className="min-w-max text-center">
                              <span className="text-sm font-semibold">
                                {module === "GROCERY" &&
                                value.quantities.notified > 0
                                  ? unit(value?.quantities?.requested)
                                  : unit(value?.quantities?.notified ?? 0)}
                              </span>
                            </div>
                          </td>
                        )}
                        {/* fulfilled */}
                        {module === "PRODUCE" && (
                          <td className="space-x-2 min-w-max">
                            <div className="min-w-max text-center">
                              <span className="text-sm font-semibold">
                                {unit(value?.quantities?.collected ?? 0)}
                              </span>
                            </div>
                          </td>
                        )}
                        {/* delivered */}
                        <td className="space-x-2 min-w-max">
                          <div className="min-w-max text-center">
                            <span className="text-sm font-semibold">
                              {unit(value?.quantities?.delivered ?? 0)}
                            </span>
                          </div>
                        </td>
                        {(module === "PRODUCE" || module === "FOOD") && (
                          <>
                            {/* average */}
                            <td className="space-x-2 min-w-max">
                              <div className="min-w-max text-center">
                                <span className="text-sm font-semibold">
                                  {currency(value.amounts?.average ?? 0)}
                                </span>
                              </div>
                            </td>
                            {/* total */}
                            <td className="space-x-2 min-w-max">
                              <div className="min-w-max text-center">
                                <span className="text-sm font-semibold">
                                  {currency(value.amounts?.total ?? 0)}
                                </span>
                              </div>
                            </td>
                          </>
                        )}
                        {/* actions */}
                        {(status === "DRAFT" || status === "WAITING") && (
                          <td>
                            <button
                              className="btn btn-sm btn-ghost text-error"
                              onClick={() => handleDelete(value.id)}
                            >
                              Remove
                            </button>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No items found
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {openComments && (
        <Drawer setOpen={setOpenComments} title={commentItem.name}>
          <CommentsList
            entity={"Item"}
            reference={commentItem.id}
            alsoRefetch={refetch}
          />
        </Drawer>
      )}
    </section>
  );
}
