import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import InventoryStats from "components/stats/Inventory";
import StocksTable from "components/tables/Stocks";
import { LIST_STOCKS } from "utils/queries";
import { CSVLink } from "react-csv";
import clsx from "clsx";
import toast from "react-hot-toast";
import { unit, formatISODate } from "utils/helpers";

export default function Inventory(props) {
  const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 3))
    .toISOString()
    .split("T")[0];

  const dateTo = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const [summary, setSummary] = useState({
    module: "",
    inwards: 0,
    outwards: 0,
    total: 0,
  });
  const [stocks, setStocks] = useState([]);
  const [options, setOptions] = useState({
    modules: [],
    locations: [],
    products: [],
  });
  const [filter, setFilter] = useState({
    module: undefined,
    location: undefined,
    product: undefined,
    date: [dateFrom, dateTo],
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [formatted, setFormatted] = useState([]);

  const { date, product } = filter;
  const [debouncedDate] = useDebounce(date, 2000);

  // find product by label from options
  const filterProduct = (product) => {
    const found = options.products.find((p) => p.label === product);
    return found?.id ?? undefined;
  };

  const { loading, refetch } = useQuery(LIST_STOCKS, {
    onCompleted: (data) => {
      if (data?.stocks?.success) {
        setSummary({
          ...summary,
          inwards: data?.stocks?.summary?.inwards,
          outwards: data?.stocks?.summary?.outwards,
          total: data?.stocks?.summary?.total,
        });

        setOptions({
          modules: data?.stocks?.options?.modules,
          locations: data?.stocks?.options?.locations,
          products: data?.stocks?.options?.products,
        });

        setStocks(data?.stocks?.list);

        setTotal(data?.stocks?.total);
        setMaxPage(Math.ceil(data?.stocks?.total / limit));

        setFormatted([]);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: !!filter.module ? filter.module : undefined,
      location: !!filter.location ? filter.location : undefined,
      product: filterProduct(product),
      date: debouncedDate,
      limit,
      page,
    },
    fetchPolicy: "network-only",
  });

  const [getCSVData, { data: download, loading: downloading }] = useLazyQuery(
    LIST_STOCKS,
    {
      onCompleted: (data) => {
        if (!data?.stocks?.success) {
          toast.error(data?.stocks?.message);
          return;
        }
        if (data?.stocks?.list.length === 0) {
          toast.error("No data found for the selected filter.");
          return;
        }
        const formattedData = data?.stocks?.list.map((item, i) => {
          return {
            No: i + 1,
            Date: formatISODate(item.date, "DD-MM-YYYY"),
            Name: item.location.label,
            Product: item.product.label,
            Category: item.category,
            Notes: item.notes,
            Inward: item.type === "IN" ? unit(item.quantity, item.unit) : "",
            Outward: item.type === "OUT" ? unit(item.quantity, item.unit) : "",
            Module: item.module,
          };
        });

        setFormatted(formattedData);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const handleDownload = () => {
    getCSVData({
      variables: {
        module: !!filter.module ? filter.module : undefined,
        location: !!filter.location ? filter.location : undefined,
        product: !!filter.product ? filter.product : undefined,
        date: debouncedDate,
        limit: total,
        page: 0,
      },
    });
  };

  return (
    <UserLayout title="Inventory">
      <HeadSection heading="Inventory">
        <>
          {/* download csv */}
          {download?.stocks?.success &&
          download?.stocks?.list.length === formatted.length ? (
            <CSVLink
              className="btn btn-primary btn-outline"
              data={formatted}
              filename={`stocks-${filter.module?.toLowerCase()}-${
                filter.date[0]
              }-${filter.date[1]}.csv`}
              onClick={(e) => setFormatted([])}
            >
              Download CSV
            </CSVLink>
          ) : (
            <button
              className={clsx("btn btn-outline", downloading && "loading")}
              onClick={handleDownload}
            >
              Generate CSV
            </button>
          )}
        </>
        <Link to={`/create/stock`} className="btn btn-secondary btn-outline">
          Add Stock
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <div>
          <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
          <InventoryStats summary={summary} />
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2 mx-2">Stocks</h2>
          <StocksTable
            loading={loading}
            refetch={refetch}
            options={options}
            filter={filter}
            setFilter={setFilter}
            stocks={stocks}
            total={total}
            page={page}
            limit={limit}
            setPage={setPage}
            maxPage={maxPage}
          />
        </div>
      </section>
    </UserLayout>
  );
}
