import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      email
      username
    }
  }
`;

export const LIST_USERS = gql`
  query Users {
    users {
      code
      success
      message
      total
      page
      limit
      list: users {
        id
        username
        email
        role
        status
      }
    }
  }
`;

export const GET_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      code
      success
      message
      user {
        id
        username
        email
        role
        status
      }
    }
  }
`;

export const LIST_LOCATIONS = gql`
  query Locations {
    locations {
      code
      success
      message
      total
      page
      limit
      list: locations {
        id
        name
        username
        email
        type
        status
        created
        updated
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query Location($id: ID!) {
    location(id: $id) {
      code
      success
      message
      location {
        id
        name
        username
        email
        type
        stocking
        xero
        restrictions
        status
        created
        updated
      }
    }
  }
`;

export const LIST_OPERATORS = gql`
  query Operators {
    operators {
      code
      success
      message
      total
      limit
      page
      list: operators {
        id
        module
        permissions
        name
        status
        created
        updated
        username
        email
      }
    }
  }
`;

export const GET_OPERATOR = gql`
  query Operator($id: ID) {
    operator(id: $id) {
      code
      success
      message
      operator {
        id
        module
        permissions
        name
        status
        created
        updated
        email
        username
      }
    }
  }
`;

export const LIST_SCHEDULES = gql`
  query Schedules {
    schedules {
      code
      success
      message
      total
      page
      limit
      list: schedules {
        id
        module
        day
        open
        close
        status
        created
        updated
      }
    }
  }
`;

export const GET_SCHEDULE = gql`
  query Schedule($id: ID!) {
    schedule(id: $id) {
      code
      success
      message
      schedule {
        id
        module
        day
        open
        close
        status
        created
        updated
      }
    }
  }
`;

export const LIST_SUPPLIERS = gql`
  query Suppliers($module: String, $exclude: [ID]) {
    suppliers(module: $module, exclude: $exclude) {
      code
      success
      message
      total
      page
      limit
      list: suppliers {
        id
        module
        name
        email
        mobile
        availability
        status
        created
        updated
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      code
      success
      message
      supplier {
        id
        module
        name
        email
        mobile
        availability
        status
        created
        updated
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query Products($name: String, $module: String, $limit: Int, $page: Int) {
    products(name: $name, module: $module, limit: $limit, page: $page) {
      code
      success
      message
      total
      page
      limit
      list: products {
        id
        module
        name
        unit
        price
        group
        thumbnail
        suppliers {
          id
          name
        }
        ratio {
          supplier {
            id
            name
          }
          percentage
        }
        status
        created
        updated
        highlighted
        count {
          suppliers
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query Product($id: ID!) {
    product(id: $id) {
      code
      success
      message
      product {
        id
        module
        name
        unit
        price
        group
        thumbnail
        suppliers {
          id
          name
        }
        ratio {
          supplier {
            id
            name
            availability
          }
          percentage
        }
        status
        created
        updated
      }
    }
  }
`;

export const PRODUCT_CONSTANTS = gql`
  query ProductConstants {
    units: productUnits {
      label
      value
    }
    groups: productGroups {
      module
      label
      value
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query Transactions(
    $module: String
    $reference: ID
    $date: [String]
    $description: String
    $category: String
    $order: ID
    $limit: Int
    $page: Int
  ) {
    transactions(
      module: $module
      reference: $reference
      date: $date
      description: $description
      category: $category
      order: $order
      limit: $limit
      page: $page
    ) {
      code
      success
      message
      total
      page
      limit
      list: transactions {
        id
        date
        module
        entity {
          id
          type
          label
        }
        type
        category
        description
        amount
        receipt
        created
        updated
      }
      summary {
        credits
        debits
        balance
      }
      options {
        modules
        entities {
          id
          type
          label
        }
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query Transaction($id: ID!) {
    transaction(id: $id) {
      code
      success
      message
      transaction {
        id
        date
        module
        entity {
          id
          type
          label
        }
        type
        category
        description
        amount
        receipt
        created
        updated
      }
    }
  }
`;

export const TRANSACTION_CONSTANTS = gql`
  query TransactionConstants($module: String, $feature: String, $role: String) {
    modules: availableModules(feature: $feature, role: $role) {
      value
      label
    }
    locations {
      locations {
        id
        name
      }
    }
    operators {
      operators {
        id
        name
      }
    }
    categories: transactionCategories(module: $module, role: $role) {
      label
      value
      entity
      type
      description
    }
  }
`;

export const LIST_STOCKS = gql`
  query Stocks(
    $module: String
    $location: ID
    $product: ID
    $date: [String]
    $limit: Int
    $page: Int
  ) {
    stocks(
      module: $module
      location: $location
      product: $product
      date: $date
      limit: $limit
      page: $page
    ) {
      code
      success
      message
      total
      page
      limit
      list: stocks {
        id
        date
        module
        type
        category
        notes
        quantity
        unit
        location {
          id
          name
        }
        product {
          id
          name
          thumbnail
        }
        created
        updated
        highlighted
      }
      summary {
        inwards
        outwards
        total
      }
      options {
        modules
        locations {
          id
          type
          label
        }
        products {
          id
          type
          label
        }
      }
    }
  }
`;

export const GET_STOCK = gql`
  query Stock($id: ID!) {
    stock(id: $id) {
      code
      success
      message
      stock {
        id
        date
        module
        type
        category
        notes
        quantity
        unit
        location {
          id
          name
        }
        product {
          id
          name
          thumbnail
        }
        created
        updated
      }
    }
  }
`;

export const STOCK_CONSTANTS = gql`
  query StockConstants($module: String, $role: String, $feature: String) {
    modules: availableModules(feature: $feature, role: $role) {
      value
      label
    }
    categories: stockCategories(module: $module, role: $role) {
      value
      label
      type
    }
    locations {
      locations {
        id
        name
      }
    }
    products: stockProducts(module: $module) {
      value
      label
      unit
      group
      module
    }
  }
`;

export const LIST_ORDERS = gql`
  query Orders(
    $module: [String]
    $number: String
    $date: [String]
    $status: [String]
    $page: Int
    $limit: Int
  ) {
    orders(
      module: $module
      number: $number
      date: $date
      status: $status
      page: $page
      limit: $limit
    ) {
      code
      success
      message
      total
      page
      limit
      list: orders {
        id
        number
        module
        date
        close
        counts {
          locations
          items
          expenses
          comments
        }
        amounts {
          total
        }
        status
        created
        updated
      }
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: ID, $number: String, $module: String, $status: String) {
    order(id: $id, number: $number, module: $module, status: $status) {
      code
      success
      message
      order {
        id
        number
        module
        date
        close
        amounts {
          cost
          expense
          total
        }
        counts {
          locations
          items
        }
        invoices {
          id
          location {
            id
            label
            type
          }
          total
          costs {
            expenses
            items
          }
          link
        }
        status
        created
        updated
      }
    }
  }
`;

export const LIST_ORDER_ITEMS = gql`
  query Items($name: String, $order: ID, $location: ID) {
    items(name: $name, order: $order, location: $location) {
      code
      success
      message
      total
      list: items {
        id
        product {
          id
          label
          type
        }
        order {
          id
          label
          type
        }
        name
        group
        unit
        thumbnail
        flags {
          collected
          delivered
          notified
          requested
        }
        highlighted
        quantities {
          requested
          notified
          collected
          delivered
        }
        amounts {
          average
          total
          rounding
        }
        counts {
          comments
        }
      }
      summary {
        collections
        deliveries
        notifications
        requests
      }
      options {
        locations {
          id
          label
          type
        }
        suppliers {
          id
          label
          type
        }
      }
    }
  }
`;

export const LIST_NOTIFIABLE_ITEMS = gql`
  query NotifiableItems($order: ID!, $supplier: ID, $name: String) {
    items: notifiableItems(order: $order, supplier: $supplier, name: $name) {
      code
      success
      message
      total
      list: notifications {
        id
        thing {
          id
          type
          label
          unit
          group
          thumbnail
          flags {
            notified
          }
        }
        quantity
        supplier {
          id
          label
          type
        }
        requested
      }
      options {
        suppliers {
          id
          label
          type
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query Comments($entity: String, $reference: ID) {
    comments(entity: $entity, reference: $reference) {
      code
      success
      message
      list: comments {
        id
        by
        on
        message
      }
    }
  }
`;

export const LIST_ORDER_EXPENSES = gql`
  query OrderExpenses($order: ID!, $individual: Boolean) {
    list: orderExpenses(order: $order, individual: $individual) {
      id
      order
      category
      description
      amount
      operator
    }
  }
`;

export const GET_EXPENSE = gql`
  query OrderExpense($id: ID!) {
    expense: orderExpense(id: $id) {
      id
      order
      category
      description
      amount
      operator
    }
  }
`;

export const DASHBOARD_ORDERS = gql`
  query Orders($s1: String, $s2: String, $s3: String, $limit: Int) {
    draftOrders: orders(status: $s1) {
      id
      number
      date
      module
      closes
      notified
    }
    processingOrders: orders(status: $s2) {
      id
      number
      date
      module
      closed
    }
    completedOrders: orders(status: $s3, limit: $limit) {
      id
      number
      date
      module
      processed
    }
  }
`;
