import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import StatementStats from "components/stats/Statement";
import TransactionsTable from "components/tables/Transactions";
import InformationSection from "components/sections/Information";
import { LIST_TRANSACTIONS } from "utils/queries";
import { CSVLink } from "react-csv";
import clsx from "clsx";
import toast from "react-hot-toast";
import { currency, formatISODate } from "utils/helpers";

export default function Statement(props) {
  const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 6))
    .toISOString()
    .split("T")[0];

  const dateTo = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const [summary, setSummary] = useState({
    // module: "PRODUCE",
    debit: 0,
    credit: 0,
    balance: 0,
  });
  const [transactions, setTransactions] = useState([]);
  const [options, setOptions] = useState({
    modules: [],
    entities: [],
  });
  const [filter, setFilter] = useState({
    module: "PRODUCE",
    reference: undefined,
    description: "",
    date: [dateFrom, dateTo],
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [formatted, setFormatted] = useState([]);

  const { description, date } = filter;
  const [debouncedDescription] = useDebounce(description, 2000);
  const [debouncedDate] = useDebounce(date, 2000);

  const { loading, refetch } = useQuery(LIST_TRANSACTIONS, {
    onCompleted: (data) => {
      if (data?.transactions?.success) {
        setSummary({
          ...summary,
          debit: data?.transactions?.summary?.debits,
          credit: data?.transactions?.summary?.credits,
          balance: data?.transactions?.summary?.balance,
        });

        setOptions({
          modules: data?.transactions?.options?.modules,
          entities: data?.transactions?.options?.entities,
        });

        setTransactions(data?.transactions?.list);

        setTotal(data?.transactions?.total);
        setMaxPage(Math.ceil(data?.transactions?.total / limit));

        setFormatted([]);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: !!filter.module ? filter.module : undefined,
      reference: !!filter.reference ? filter.reference : undefined,
      date: debouncedDate,
      description: !!debouncedDescription ? debouncedDescription : undefined,
      limit,
      page,
    },
    fetchPolicy: "network-only",
  });

  const [getCSVData, { data: download, loading: downloading }] = useLazyQuery(
    LIST_TRANSACTIONS,
    {
      onCompleted: (data) => {
        if (!data?.transactions?.success) {
          toast.error(data?.transactions?.message);
          return;
        }
        if (data?.transactions?.list.length === 0) {
          toast.error("No data found for the selected filter.");
          return;
        }
        const formattedData = data?.transactions?.list.map((item, i) => {
          return {
            No: i + 1,
            Date: formatISODate(item.date, "DD-MM-YYYY"),
            Name: item.entity.label,
            Type: item.entity.type,
            Category: item.category,
            Description: item.description,
            Credit: item.type === "CREDIT" ? currency(item.amount) : "",
            Debit: item.type === "DEBIT" ? currency(item.amount) : "",
            Module: item.module,
          };
        });

        setFormatted(formattedData);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const handleDownload = () => {
    getCSVData({
      variables: {
        module: !!filter.module ? filter.module : undefined,
        reference: !!filter.reference ? filter.reference : undefined,
        date: debouncedDate,
        description: !!debouncedDescription ? debouncedDescription : undefined,
        limit: total,
        page: 0,
      },
    });
  };

  return (
    <UserLayout title="Statement">
      <HeadSection heading="Statement">
        <>
          {/* download csv */}
          {download?.transactions?.success &&
          download?.transactions?.list.length === formatted.length ? (
            <CSVLink
              className="btn btn-primary btn-outline"
              data={formatted}
              filename={`transactions-${filter.module?.toLowerCase()}-${
                filter.date[0]
              }-${filter.date[1]}.csv`}
              onClick={(e) => setFormatted([])}
            >
              Download CSV
            </CSVLink>
          ) : (
            <button
              className={clsx("btn btn-outline", downloading && "loading")}
              onClick={handleDownload}
            >
              Generate CSV
            </button>
          )}
        </>
        <Link
          to={`/create/transaction`}
          className="btn btn-secondary btn-outline"
        >
          Add Transaction
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <div>
          <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
          <StatementStats summary={summary} />
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2 mx-2">Transactions</h2>
          <TransactionsTable
            loading={loading}
            refetch={refetch}
            options={options}
            filter={filter}
            setFilter={setFilter}
            transactions={transactions}
            total={total}
            page={page}
            setPage={setPage}
            limit={limit}
            maxPage={maxPage}
          />
        </div>
      </section>
      <InformationSection title={`Information`}>
        <p className="italic font-bold">How to read the statement:</p>
        <p>
          <strong>For Locations & Operators: </strong>
          <span>
            A positive amount, means you owe them. And a negative amount means
            they owe you.
          </span>
        </p>
        <p>
          Note: this statement is calculated based on the perspective of the
          company i.e. that is taking the company into account.
        </p>
      </InformationSection>
    </UserLayout>
  );
}
