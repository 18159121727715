import { useState, useEffect, useContext } from "react";
import { AuthContext } from "contexts/Auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_STOCK, UPDATE_STOCK } from "utils/mutations";
import { STOCK_CONSTANTS, LIST_STOCKS, GET_STOCK } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { formatISODate } from "utils/helpers";

export default function StockForm(props) {
  const { id } = props;

  const { user } = useContext(AuthContext);

  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  const today = new Date().toISOString().split("T")[0];

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const [modules, setModules] = useState([]);
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm({ mode: "onTouched" });

  const { stock_module, stock_category } = watch();

  const { loading: initialising } = useQuery(STOCK_CONSTANTS, {
    onCompleted: (data) => {
      setModules(data?.modules);
      setLocations(data?.locations?.locations);
      setProducts(data?.products);
      setCategories(data?.categories);
      if (mode === "edit") getStock({ variables: { id } });
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: !!stock_module ? stock_module : null,
      feature: "STOCK",
      role: user?.data?.role,
    },
  });

  const [createStock, { loading: creating }] = useMutation(CREATE_STOCK, {
    onCompleted: (data) => {
      if (!data?.created?.success)
        toast.error("Stock creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Stock created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_STOCKS }],
  });

  const [updateStock, { loading: updating }] = useMutation(UPDATE_STOCK, {
    onCompleted: (data) => {
      if (!data?.updated?.success)
        toast.error("Stock update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Stock updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [
      { query: LIST_STOCKS },
      { query: GET_STOCK, variables: { id: id } },
    ],
  });

  const [getStock, { loading: fetching }] = useLazyQuery(GET_STOCK, {
    onCompleted: (data) => {
      if (data?.stock?.category === "CHARGE") navigate("/inventory");
      if (data?.stock?.success) {
        const { stock } = data.stock;
        setValue("stock_module", stock.module);
        setValue("stock_date", formatISODate(stock.date, "YYYY-MM-DD"));
        setValue("stock_quantity", parseInt(stock.quantity) / 100);
        setValue("stock_category", stock.category);
        setValue("stock_type", stock.type);
        setValue("stock_product", stock.product?.id);
        setValue("stock_location", stock.location?.id);
        setValue("stock_notes", stock.notes);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    const payload = {
      module: data.stock_module,
      date: data.stock_date,
      category: data.stock_category,
      type: data.stock_type,
      product: data.stock_product,
      location: data.stock_location,
      quantity: parseInt(data.stock_quantity * 100),
      notes: data.stock_notes,
    };

    const inputs = mode === "edit" ? { id, ...payload } : payload;

    if (mode === "edit") updateStock({ variables: { inputs } });
    else createStock({ variables: { inputs } });
  };

  useEffect(() => {
    if (initialising || fetching || creating || updating) setLoading(true);
    if (!initialising && !fetching && !creating && !updating) setLoading(false);
  }, [initialising, fetching, creating, updating, loading]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* module */}
        <div>
          <label htmlFor="module" className="label">
            <span className="label-text font-semibold">Module</span>
          </label>
          <select
            name="module"
            disabled={mode === "edit"}
            className="input input-bordered w-full bg-base-200"
            {...register("stock_module", {
              required: "Module is required",
            })}
          >
            <option value={""}>Select module</option>
            {modules.map((module, index) => (
              <option key={index} value={module.value}>
                {module.label}
              </option>
            ))}
          </select>
          {errors?.stock_module && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_module?.message}</small>
            </div>
          )}
        </div>
        {/* date */}
        <div>
          <label htmlFor="date" className="label">
            <span className="label-text font-semibold">Date</span>
          </label>
          <input
            type="date"
            name="date"
            max={formatISODate(tomorrow, "YYYY-MM-DD")}
            value={today}
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("stock_date", {
              required: "Date is required",
              max: {
                value: formatISODate(tomorrow, "YYYY-MM-DD"),
                message: "Date cannot be in the future",
              },
            })}
          />
          {errors?.stock_date && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_date?.message}</small>
            </div>
          )}
        </div>
        {/* category */}
        <div>
          <label htmlFor="category" className="label">
            <span className="label-text font-semibold">Category</span>
          </label>
          <select
            name="category"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("stock_category", {
              required: "Category is required",
            })}
          >
            <option value={""}>Select category</option>
            {categories.map((category, index) => (
              <option key={index} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
          {errors?.stock_category && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_category?.message}</small>
            </div>
          )}
        </div>
        {/* type */}
        <div>
          <label htmlFor="type" className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            name="type"
            className="input input-bordered w-full bg-base-200"
            disabled={stock_category !== "ADJUSTMENT" || loading}
            {...register("stock_type", {
              required: "Type is required",
            })}
          >
            <option value={""}>Select type</option>
            <option value={"IN"}>Inwards (Add to inventory)</option>
            <option value={"OUT"}>Outwards (Remove from inventory)</option>
          </select>
          {errors?.stock_type && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_type?.message}</small>
            </div>
          )}
        </div>
        {/* product */}
        <div>
          <label htmlFor="product" className="label">
            <span className="label-text font-semibold">Product</span>
          </label>
          <select
            name="product"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("stock_product", {
              required: "Product is required",
            })}
          >
            <option value={""}>Select product</option>
            {products.map((product, index) => (
              <option key={index} value={product.value}>
                {product.group} / {product.label}
              </option>
            ))}
          </select>
          {errors?.stock_product && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_product?.message}</small>
            </div>
          )}
        </div>
        {/* location */}
        <div>
          <label htmlFor="location" className="label">
            <span className="label-text font-semibold">Location</span>
          </label>
          <select
            name="location"
            className="input input-bordered w-full bg-base-200"
            disabled={loading || mode === "edit"}
            {...register("stock_location", {
              required: "Location is required",
            })}
          >
            <option value={""}>Select location</option>
            {locations.map((location, index) => (
              <option key={index} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
          {errors?.stock_location && (
            <div className="mt-1 text-red-600">
              <small>{errors?.stock_location?.message}</small>
            </div>
          )}
        </div>
        {/* quantity */}
        <div>
          <label htmlFor="quantity" className="label">
            <span className="label-text font-semibold">Quantity</span>
          </label>
          <input
            type="number"
            name="quantity"
            placeholder="Quantity"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("stock_quantity", {
              required: "Quantity is required",
              min: {
                value: 1,
                message: "Quantity must be at least 1",
              },
            })}
          />
          {errors?.stock_quantity && (
            <div className="text-red-600">
              <small>{errors?.stock_quantity?.message}</small>
            </div>
          )}
        </div>
        {/* notes */}
        <div>
          <label htmlFor="note" className="label">
            <span className="label-text font-semibold">Note</span>
          </label>
          <input
            type="text"
            name="note"
            rows={5}
            placeholder="Note"
            defaultValue={""}
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("stock_notes", {
              required: "Note is required",
            })}
          />
          {errors?.stock_notes && (
            <div className="text-red-600">
              <small>{errors?.stock_notes?.message}</small>
            </div>
          )}
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", loading && "loading")}
            disabled={!isValid || loading}
          >
            {mode === "edit" ? "Update stock" : "Create stock"}
          </button>
        </div>
      </form>
    </div>
  );
}
