import { compactUnit, unit } from "utils/helpers";

export default function InventoryStats(props) {
  const { inwards, outwards, total } = props.summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded w-full bg-base-200">
      <div className="stat">
        <div className="stat-title">Inward</div>
        <div className="stat-value font-bold text-2xl">{unit(inwards)}</div>
        <div className="stat-desc">Total bought</div>
      </div>

      <div className="stat">
        <div className="stat-title">Outward</div>
        <div className="stat-value font-bold text-2xl">{unit(outwards)}</div>
        <div className="stat-desc">Total used / sold</div>
      </div>

      <div className="stat">
        <div className="stat-title">Total</div>
        <div className="stat-value font-bold text-2xl">
          {compactUnit(total)}
        </div>
        <div className="stat-desc">Net total</div>
      </div>
    </div>
  );
}
