import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_STOCK } from "utils/mutations";
import { LoadingTable } from "layouts/Loading";
import toast from "react-hot-toast";
import clsx from "clsx";
import { unit, formatISODate, upperFirst, capitalize } from "utils/helpers";
import {
  ArrowPathIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export default function StocksTable(props) {
  const {
    loading,
    refetch,
    options,
    filter,
    setFilter,
    stocks,
    total,
    page,
    limit,
    setPage,
    maxPage,
  } = props;

  const [deleteStock] = useMutation(DELETE_STOCK, {
    onCompleted: (data) => {
      toast.success("Stock deleted successfully.");
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const canEditStock = (category) => {
    if (category === "ADJUSTMENT") return true;
    if (category === "USAGE") return true;
    if (category === "ADDED") return true;
    return false;
  };

  const canDeleteStock = (category) => {
    if (category === "ADJUSTMENT") return true;
    if (category === "USAGE") return true;
    if (category === "ADDED") return true;
    return false;
  };

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this stock?")) return;
    deleteStock({ variables: { id } });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <header className="flex flex-col md:flex-row justify-between p-2 gap-4">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-3 items-center">
          {/* module */}
          <select
            className="input input-bordered w-full md:w-36 bg-base-200"
            name="module"
            value={filter.module ?? ""}
            onChange={(e) => setFilter({ ...filter, module: e.target.value })}
          >
            <option value="">All Modules</option>
            {options?.modules?.map((value, index) => (
              <option key={index} value={value}>
                {capitalize(value)}
              </option>
            ))}
          </select>
          {/* location */}
          <select
            className="input input-bordered w-full md:w-40 bg-base-200"
            name="reference"
            value={filter.location ?? ""}
            onChange={(e) => setFilter({ ...filter, location: e.target.value })}
          >
            <option value="">All Locations</option>
            {options?.locations?.map((value, index) => (
              <option key={index} value={value.id}>
                {value.label}
              </option>
            ))}
          </select>
          {/* product */}
          <input
            type="search"
            list="products"
            className="input input-bordered w-full md:w-64 bg-base-200"
            placeholder="Product"
            htmlFor="products"
            value={filter.product ?? ""}
            onChange={(e) => setFilter({ ...filter, product: e.target.value })}
          />
          <datalist id="products">
            {options?.products?.map((value, index) => (
              <option key={index} value={value.label} data-id={value.id}>
                {value.label}
              </option>
            ))}
          </datalist>
          <div className="flex items-center gap-2 w-full md:w-96">
            {/* date[0] */}
            <input
              type="date"
              className="input input-bordered w-full md:w-40 bg-base-200"
              placeholder="From"
              max={filter.date[1]}
              value={filter.date[0]}
              onChange={(e) =>
                setFilter({ ...filter, date: [e.target.value, filter.date[1]] })
              }
            />
            <span className="text-xs">to</span>
            {/* date[1] */}
            <input
              type="date"
              className="input input-bordered w-full md:w-40 bg-base-200"
              placeholder="To"
              min={filter.date[0]}
              value={filter.date[1]}
              onChange={(e) =>
                setFilter({ ...filter, date: [filter.date[0], e.target.value] })
              }
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center justify-end">
          <button className="btn btn-sm btn-ghost" onClick={() => refetch()}>
            <ArrowPathIcon className="w-4 h-4" />
          </button>
        </div>
      </header>
      <main className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Date</th>
              <th className="text-xs">Name</th>
              <th className="text-xs">Details</th>
              <th className="text-center text-xs">Out</th>
              <th className="text-center text-xs">In</th>
              <th className="text-xs float-right mr-[42px]">Action</th>
            </tr>
          </thead>
          {loading ? (
            <LoadingTable rows={limit} cols={6} className="h-8" />
          ) : (
            <tbody className="divide-y-2">
              {stocks && stocks.length > 0 ? (
                stocks.map((value, index) => (
                  <tr key={index} className="text-left">
                    <td>{page * limit + index + 1}</td>
                    <th className="min-w-[130px] max-w-[130px]">
                      {formatISODate(value.date, "DD-MM-YYYY")}
                    </th>
                    <td className="max-w-[120px]">{value.location?.name}</td>
                    <td className="flex flex-row items-center rounded min-w-max">
                      <div className="flex-shrink-0 w-16">
                        <img
                          src={value.product?.thumbnail}
                          className="min-w-16 min-h-16 w-16 h-16 object-cover"
                          alt={value.product?.name}
                        />
                      </div>
                      <div className="flex-1 flex-shrink-0 p-4">
                        <h3 className="text-lg font-semibold tracking-wider">
                          {value.product?.name}
                        </h3>
                        <p>
                          <span className="font-normal">
                            {upperFirst(value.category.toLowerCase())}
                          </span>{" "}
                          {value.notes && <span>/ {value.notes}</span>}
                        </p>
                      </div>
                    </td>
                    <th className="text-center">
                      {value.type === "OUT"
                        ? unit(value.quantity, value.unit)
                        : `-`}
                    </th>
                    <th className="text-center">
                      {value.type === "IN"
                        ? unit(value.quantity, value.unit)
                        : `-`}
                    </th>
                    <td className="space-x-2 min-w-[120px] max-w-[120px] float-right">
                      <Link
                        className={clsx(
                          "btn btn-sm btn-circle btn-outline",
                          !canEditStock(value.category) && "btn-disabled"
                        )}
                        to={`/update/stock/${value.id}`}
                      >
                        <PencilIcon className="w-3 h-3" />
                      </Link>
                      <button
                        className={clsx("btn btn-sm btn-circle btn-outline")}
                        disabled={!canDeleteStock(value.category)}
                        onClick={() => handleDelete(value.id)}
                      >
                        <TrashIcon className="w-3 h-3" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No transactions found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </main>
      <footer className="flex flex-col justify-between p-2">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-4">
          <div>
            <p>
              Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
              <span className="font-bold">{page * limit + stocks?.length}</span>{" "}
              of <span className="font-bold">{total}</span> results
            </p>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              disabled={page === 0 || maxPage <= 1}
              onClick={() => prevPage()}
            >
              Prev
            </button>
            <button
              className="btn btn-outline btn-sm"
              disabled={page === maxPage - 1 || maxPage <= 1}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}
