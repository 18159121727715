import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { CREATE_PRODUCT, UPDATE_PRODUCT } from "utils/mutations";
import { GET_PRODUCT, LIST_PRODUCTS, PRODUCT_CONSTANTS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import ProductSuppliersForm from "./ProductSuppliers";
import ProductThumbnailForm from "./ProductThumbnail";
import ProductSuppliersList from "components/lists/ProductSuppliers";

export default function ProductForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const [units, setUnits] = useState([]);
  const [groups, setGroups] = useState([]);
  const [applicableGroups, setApplicableGroups] = useState([]);
  const [infoUnit, setInfoUnit] = useState("");
  const [infoGroup, setInfoGroup] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const { product_module } = watch();

  useQuery(PRODUCT_CONSTANTS, {
    onCompleted: (data) => {
      setUnits(data?.units);
      setGroups(data?.groups);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.created?.success)
        toast.error("Product creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Product created successfully");
        reset();
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_PRODUCTS, variables: { module: null } }],
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.updated?.success)
        toast.error("Product update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Product updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [
      { query: LIST_PRODUCTS, variables: { module: null } },
      { query: GET_PRODUCT, variables: { id } },
    ],
  });

  const [getProduct, { data: productData }] = useLazyQuery(GET_PRODUCT, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.product?.success) {
        const { product } = data.product;
        updateApplicableGroups(product?.module);
        setInfoUnit(product?.unit);
        setInfoGroup(product?.group);
        setValue("product_module", product?.module);
        setValue("product_name", product?.name);
        setValue("product_status", product?.status);
        if (product?.module === "FOOD")
          setValue("product_price", product?.price / 100);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    setLoading(true);

    const payload = {
      module: data.product_module,
      name: data.product_name,
      unit: data.product_unit,
      group: data.product_group,
      status: data.product_status,
    };

    if (product_module === "FOOD")
      payload.price = parseInt(data.product_price * 100);

    const inputs = mode === "edit" ? { id, ...payload } : { ...payload };

    if (mode === "edit") updateProduct({ variables: { inputs } });
    else createProduct({ variables: { inputs } });
  };

  const updateApplicableGroups = (value) => {
    setApplicableGroups(groups.filter((g) => g.module === value));
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getProduct({ variables: { id } });
    }
  }, [getProduct, id, mode]);

  useEffect(() => {
    if (infoUnit !== "") setValue("product_unit", infoUnit);
  }, [infoUnit, setValue]);

  useEffect(() => {
    if (infoGroup !== "") setValue("product_group", infoGroup);
  }, [infoGroup, setValue]);

  return (
    <div className="flex flex-col sm:flex-row gap-6 max-w-3xl">
      {/* info */}
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* module */}
          <div>
            <label htmlFor="module" className="label">
              <span className="label-text font-semibold">Module</span>
            </label>
            <select
              name="module"
              className="input input-bordered w-full bg-base-200"
              disabled={loading || mode === "edit"}
              {...register("product_module", {
                required: "Module is required",
              })}
              onChange={(e) => updateApplicableGroups(e.target.value)}
            >
              <option value="">Select module</option>
              <option value="PRODUCE">Produce</option>
              <option value="GROCERY">Grocery</option>
              <option value="FOOD">Food</option>
            </select>
            {errors?.product_module && (
              <div className="mt-1 text-red-600">
                <small>{errors?.product_module?.message}</small>
              </div>
            )}
          </div>
          {/* name */}
          <div>
            <label htmlFor="name" className="label">
              <span className="label-text font-semibold">Name</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Product name"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("product_name", {
                required: "Name is required",
              })}
            />
            {errors?.product_name && (
              <div className="mt-1 text-red-600">
                <small>{errors?.product_name?.message}</small>
              </div>
            )}
          </div>
          {/* unit */}
          {units.length > 0 ? (
            <div>
              <label htmlFor="unit" className="label">
                <span className="label-text font-semibold">Unit</span>
              </label>
              <select
                name="unit"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("product_unit", {
                  required: "Unit is required",
                })}
              >
                <option value="">Select unit</option>
                {units.map((unit, index) => (
                  <option key={index} value={unit.value}>
                    {unit.label}
                  </option>
                ))}
              </select>
              {errors?.product_unit && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.product_unit?.message}</small>
                </div>
              )}
            </div>
          ) : null}
          {/* group */}
          {applicableGroups.length > 0 ? (
            <div>
              <label htmlFor="group" className="label">
                <span className="label-text font-semibold">Group</span>
              </label>
              <select
                name="group"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("product_group", {
                  required: "Group is required",
                })}
              >
                <option value="">Select group</option>
                {applicableGroups.map((group, index) => (
                  <option key={index} value={group.value}>
                    {group.label}
                  </option>
                ))}
              </select>
              {errors?.product_group && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.product_group?.message}</small>
                </div>
              )}
            </div>
          ) : null}
          {/* price */}
          {product_module === "FOOD" ? (
            <div>
              <label htmlFor="price" className="label">
                <span className="label-text font-semibold">Price</span>
              </label>
              <input
                type="number"
                name="price"
                placeholder="Product price"
                step="0.01"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("product_price", {
                  required: "Price is required",
                })}
              />
              {errors?.product_price && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.product_price?.message}</small>
                </div>
              )}
            </div>
          ) : null}
          {/* status */}
          {mode === "edit" ? (
            <div>
              <label htmlFor="status" className="label">
                <span className="label-text font-semibold">Status</span>
              </label>
              <select
                name="status"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("product_status", {
                  required: "Status is required",
                })}
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
                <option value="INSEASON">In-season</option>
              </select>
              {errors?.product_status && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.product_status?.message}</small>
                </div>
              )}
            </div>
          ) : null}
          {/* submit */}
          <div className="pt-4">
            <button
              className={clsx(
                "btn btn-primary",
                `${loading ? "loading" : null}`
              )}
            >
              {mode === "edit" ? "Update product info" : "Create product"}
            </button>
          </div>
        </form>
        {/* suppliers */}
        {mode === "edit" &&
        productData?.product?.success &&
        product_module !== "FOOD" ? (
          <div className="border-t-2 mt-4 pt-4">
            <ProductSuppliersList
              id={id}
              list={productData?.product?.product?.ratio}
              module={productData?.product?.product?.module}
            />
            <ProductSuppliersForm
              product={productData?.product?.product}
              list={productData?.product?.product?.ratio}
            />
          </div>
        ) : null}
      </div>
      {mode === "edit" && productData?.product?.success ? (
        <div>
          {/* thumbnail */}
          <ProductThumbnailForm product={productData?.product?.product} />
        </div>
      ) : null}
    </div>
  );
}
