import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import SuppliersTable from "components/tables/Suppliers";

export default function Suppliers(props) {
  return (
    <UserLayout title="Suppliers">
      <HeadSection heading="Suppliers">
        <Link to={`/create/supplier`} className="btn btn-secondary btn-outline">
          Add Supplier
        </Link>
      </HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <SuppliersTable />
      </section>
    </UserLayout>
  );
}
