import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_TRANSACTION } from "utils/mutations";
import { LoadingTable } from "layouts/Loading";
import toast from "react-hot-toast";
import clsx from "clsx";
import { currency, formatISODate, upperFirst, receiptUri } from "utils/helpers";
import {
  ArrowPathIcon,
  PencilIcon,
  DocumentTextIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export default function TransactionsTable(props) {
  const {
    loading,
    refetch,
    options,
    filter,
    setFilter,
    transactions,
    total,
    page,
    limit,
    setPage,
    maxPage,
  } = props;

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
    onCompleted: (data) => {
      toast.success("Transaction deleted successfully.");
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const canEditTransaction = (category) => {
    if (category === "CHARGE") return false;
    if (category === "EXPENSE") return false;
    return true;
  };

  const handleReceipt = (receipt) => {
    if (receipt === null) return;
    window.open(receiptUri(receipt), "_blank");
  };

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this transaction?"))
      return;
    deleteTransaction({ variables: { id } });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <header className="flex flex-col md:flex-row justify-between p-2 gap-4">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-2 items-center">
          {/* module */}
          <select
            className="input input-bordered w-full md:w-32 bg-base-200"
            name="module"
            value={
              filter.module ? filter.module : options?.modules[0] || "PRODUCE"
            }
            disabled={true}
            onChange={(e) => setFilter({ ...filter, module: e.target.value })}
          >
            <option value="">All Modules</option>
            {options?.modules?.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
          {/* reference */}
          <select
            className="input input-bordered w-full md:w-64 bg-base-200"
            name="reference"
            value={filter.reference ?? ""}
            onChange={(e) =>
              setFilter({ ...filter, reference: e.target.value })
            }
          >
            <option value="">All Entities</option>
            {options?.entities?.map((value, index) => (
              <option key={index} value={value.id}>
                {value.label} ({value.type})
              </option>
            ))}
          </select>
          {/* descrition */}
          <input
            type="text"
            className="input input-bordered w-full md:w-80 bg-base-200"
            placeholder="Description"
            value={filter.description ?? ""}
            onChange={(e) =>
              setFilter({ ...filter, description: e.target.value })
            }
          />
          <div className="flex items-center gap-2 w-full md:w-96">
            {/* date[0] */}
            <input
              type="date"
              className="input input-bordered w-full md:w-40 bg-base-200"
              placeholder="From"
              max={filter.date[1]}
              value={filter.date[0]}
              onChange={(e) =>
                setFilter({ ...filter, date: [e.target.value, filter.date[1]] })
              }
            />
            <span className="text-xs">to</span>
            {/* date[1] */}
            <input
              type="date"
              className="input input-bordered w-full md:w-40 bg-base-200"
              placeholder="To"
              min={filter.date[0]}
              value={filter.date[1]}
              onChange={(e) =>
                setFilter({ ...filter, date: [filter.date[0], e.target.value] })
              }
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center justify-end">
          <button className="btn btn-sm btn-ghost" onClick={() => refetch()}>
            <ArrowPathIcon className="w-4 h-4" />
          </button>
        </div>
      </header>
      <main className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Date</th>
              <th className="text-xs">Name</th>
              <th className="text-xs">Details</th>
              <th className="text-center text-xs">Debit</th>
              <th className="text-center text-xs">Credit</th>
              <th className="text-xs float-right mr-[68px]">Action</th>
            </tr>
          </thead>
          {loading ? (
            <LoadingTable rows={limit} cols={6} className="h-6" />
          ) : (
            <tbody className="divide-y-2">
              {transactions && transactions.length > 0 ? (
                transactions.map((value, index) => (
                  <tr key={index} className="text-left">
                    <td>{page * limit + index + 1}</td>
                    <th className="min-w-[130px] max-w-[130px]">
                      {formatISODate(value.date, "DD-MM-YYYY")}
                    </th>
                    <td className="max-w-[160px]">
                      {value.entity?.label ?? "-"}
                    </td>
                    <td className="min-w-[200px] max-w-xs whitespace-pre-line">
                      <span className="font-bold">
                        {upperFirst(value.category.toLowerCase())}
                      </span>
                      <br />
                      {value.description ?? "-"}
                    </td>
                    <th className="text-center">
                      {value.type === "DEBIT" ? currency(value.amount) : `-`}
                    </th>
                    <th className="text-center">
                      {value.type === "CREDIT" ? currency(value.amount) : `-`}
                    </th>
                    <td className="space-x-2 min-w-[150px] max-w-[150px] float-right">
                      <button
                        className={clsx(
                          "btn btn-sm btn-circle btn-outline",
                          `${!value.receipt ? "btn-disabled" : null}`
                        )}
                        onClick={() => handleReceipt(value.receipt)}
                      >
                        <DocumentTextIcon className="w-3 h-3" />
                      </button>
                      <Link
                        className={clsx(
                          "btn btn-sm btn-circle btn-outline",
                          !canEditTransaction(value.category) && "btn-disabled"
                        )}
                        to={`/update/transaction/${value.id}`}
                      >
                        <PencilIcon className="w-3 h-3" />
                      </Link>
                      <button
                        className={clsx(
                          "btn btn-sm btn-circle btn-outline",
                          !canEditTransaction(value.category) && "btn-disabled"
                        )}
                        onClick={() => handleDelete(value.id)}
                      >
                        <TrashIcon className="w-3 h-3" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No transactions found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </main>
      <footer className="flex flex-col justify-between p-2">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-4">
          <div>
            <p>
              Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
              <span className="font-bold">
                {page * limit + transactions?.length}
              </span>{" "}
              of <span className="font-bold">{total}</span> results
            </p>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              disabled={page === 0 || maxPage <= 1}
              onClick={() => prevPage()}
            >
              Prev
            </button>
            <button
              className="btn btn-outline btn-sm"
              disabled={page === maxPage - 1 || maxPage <= 1}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}
