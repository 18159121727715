import { currency } from "utils/helpers";

export default function StatementStats(props) {
  const { debit, credit, balance } = props.summary;

  return (
    <div className="stats stats-vertical md:stats-horizontal rounded w-full bg-base-200">
      <div className="stat">
        <div className="stat-title">Debit</div>
        <div className="stat-value font-bold text-2xl">{currency(debit)}</div>
        <div className="stat-desc">Total charges</div>
      </div>

      <div className="stat">
        <div className="stat-title">Credit</div>
        <div className="stat-value font-bold text-2xl">{currency(credit)}</div>
        <div className="stat-desc">Total payments</div>
      </div>

      <div className="stat">
        <div className="stat-title">Balance</div>
        <div className="stat-value font-bold text-2xl">{currency(balance)}</div>
        <div className="stat-desc">Net total</div>
      </div>
    </div>
  );
}
