import { useState, useContext } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import UserForm from "components/forms/User";
import LocationForm from "components/forms/Location";
import OperatorForm from "components/forms/Operator";
import ScheduleForm from "components/forms/Schedule";
import ProductForm from "components/forms/Product";
import SupplierForm from "components/forms/Supplier";
import StockForm from "components/forms/Stock";
import TransactionForm from "components/forms/Transaction";
import OrderForm from "components/forms/Order";
import CommentForm from "components/forms/Comment";

export default function Create(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");
  const [isManager] = useState(user.data.role === "MANAGER");

  const { type } = useParams();
  const navigate = useNavigate();

  return (
    <UserLayout title={`Create ${type}`}>
      <HeadSection heading={`Create ${type}`}>
        <button
          className="btn btn-secondary btn-outline"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8">
        {type && basedOnType(type, isAdmin, isManager)}
      </section>
    </UserLayout>
  );
}

const basedOnType = (type, isAdmin, isManager) => {
  if (isAdmin) {
    switch (type) {
      // user
      case "user":
        return <UserForm />;
      // location
      case "location":
        return <LocationForm />;
      // operator
      case "operator":
        return <OperatorForm />;
      // schedule
      case "schedule":
        return <ScheduleForm />;
      // product
      case "product":
        return <ProductForm />;
      // stock
      case "stock":
        return <StockForm />;
      // supplier
      case "supplier":
        return <SupplierForm />;
      // transaction
      case "transaction":
        return <TransactionForm />;
      // order
      case "order":
        return <OrderForm />;
      // comment
      case "comment":
        return <CommentForm />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else if (isManager) {
    switch (type) {
      // schedule
      case "schedule":
        return <ScheduleForm />;
      // product
      case "product":
        return <ProductForm />;
      // supplier
      case "supplier":
        return <SupplierForm />;
      // stock
      case "stock":
        return <StockForm />;
      // transaction
      case "transaction":
        return <TransactionForm />;
      // order
      case "order":
        return <OrderForm />;
      // comment
      case "comment":
        return <CommentForm />;
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  } else {
    switch (type) {
      // otherwise
      default:
        return <Navigate to="/" />;
    }
  }
};
