import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import logo from "assets/logo.svg";
import {
  Bars3Icon,
  EllipsisHorizontalIcon,
  ChartBarIcon,
  QueueListIcon,
  InboxStackIcon,
  Square2StackIcon,
  ClipboardDocumentListIcon,
  UsersIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";

const year = new Date().getFullYear();

export default function UserLayout(props) {
  const { user, logout } = useContext(AuthContext);
  const [isAdmin /*, setIsAdmin*/] = useState(user?.data?.role === "ADMIN");
  const [isManager /*, setIsManager*/] = useState(
    user?.data?.role === "MANAGER"
  );

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <div>
        <div>
          <div className="drawer drawer-mobile">
            <input
              id="app-menu-drawer"
              type="checkbox"
              className="drawer-toggle"
            />
            <div className="drawer-content w-full flex flex-col items-center">
              {/* page content */}
              <header className="w-full">
                <div className="navbar bg-base-200 py-4 px-4">
                  <div className="w-full flex justify-between mx-auto">
                    <div className="flex gap-2 justify-center items-center">
                      <div className="flex-none lg:hidden">
                        <label
                          htmlFor="app-menu-drawer"
                          className="btn btn-ghost text-secondary btn-secondary"
                        >
                          <Bars3Icon className="inline-block w-6 h-6 stroke-current" />
                        </label>
                      </div>
                      <Link className="lg:hidden" to="/">
                        <img src={logo} alt="App logo" className="w-36" />
                      </Link>
                    </div>
                    <div>
                      <div className="flex gap-2 z-40">
                        <div className="dropdown dropdown-end">
                          <label
                            tabIndex="0"
                            className="btn btn-secondary btn-outline"
                          >
                            <EllipsisHorizontalIcon className="inline-block w-5 h-5 stroke-current" />
                          </label>
                          <ul
                            tabIndex="0"
                            className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 mt-6"
                          >
                            <li>
                              <Link
                                className="uppercase text-secondary font-semibold"
                                to="/profile"
                              >
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <div
                                className="uppercase text-secondary font-semibold"
                                onClick={() => logout()}
                              >
                                Logout
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <main className="w-full max-w-7xl mx-auto py-8 px-4">
                {props.children}
              </main>
              <footer className="w-full max-w-7xl mx-auto pt-8 px-4">
                <div className="footer footer-center p-4 text-base-content border-t-2">
                  <div>
                    <p>
                      Copyright © {year} - All right reserved by Radhe Group -
                      Powered by{" "}
                      <a
                        href="https://logicsync.au"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LogicSync
                      </a>
                    </p>
                  </div>
                </div>
              </footer>
            </div>
            <div className="drawer-side">
              <label
                htmlFor="app-menu-drawer"
                className="drawer-overlay"
              ></label>
              <ul className="menu p-4 overflow-y-auto w-80 bg-base-200 text-secondary font-semibold uppercase tracking-wider text-sm">
                <div className="flex gap-2 justify-start items-center">
                  <Link className="hidden lg:block pb-6" to="/">
                    <img src={logo} alt="App logo" className="w-48" />
                  </Link>
                </div>
                {/* sidebar content */}
                {isAdmin &&
                  adminMenu.map((item, index) => (
                    <li key={index}>
                      <NavLink to={item.path}>
                        {item.icon}
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                {isManager &&
                  managerMenu.map((item, index) => (
                    <li key={index}>
                      <NavLink to={item.path}>
                        {item.icon}
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const adminMenu = [
  {
    label: "Dashboard",
    path: "/",
    icon: <ChartBarIcon className="h-6 w-6" />,
  },
  {
    label: "Orders",
    path: "/orders",
    icon: <ClipboardDocumentListIcon className="h-6 w-6" />,
  },
  {
    label: "Statement",
    path: "/statement",
    icon: <QueueListIcon className="h-6 w-6" />,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <InboxStackIcon className="h-6 w-6" />,
  },
  {
    label: "Products",
    path: "/products",
    icon: <Square2StackIcon className="h-6 w-6" />,
  },
  {
    label: "Suppliers",
    path: "/suppliers",
    icon: <BuildingStorefrontIcon className="h-6 w-6" />,
  },
  {
    label: "Config",
    path: "/config?tab=schedule",
    icon: <UsersIcon className="h-6 w-6" />,
  },
];

const managerMenu = [
  {
    label: "Dashboard",
    path: "/",
    icon: <ChartBarIcon className="h-6 w-6" />,
  },
  {
    label: "Orders",
    path: "/orders",
    icon: <ClipboardDocumentListIcon className="h-6 w-6" />,
  },
  {
    label: "Statement",
    path: "/statement",
    icon: <QueueListIcon className="h-6 w-6" />,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <InboxStackIcon className="h-6 w-6" />,
  },
  {
    label: "Products",
    path: "/products",
    icon: <Square2StackIcon className="h-6 w-6" />,
  },
  {
    label: "Suppliers",
    path: "/suppliers",
    icon: <QueueListIcon className="h-6 w-6" />,
  },
  {
    label: "Config",
    path: "/config?tab=schedule",
    icon: <UsersIcon className="h-6 w-6" />,
  },
];
