import clsx from "clsx";

export default function LoadingLayout() {
  return (
    <div className="mx-auto w-full rounded-md border p-4">
      <div className="flex animate-pulse space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-6 rounded bg-slate-200"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-4 rounded bg-slate-200"></div>
              <div className="col-span-1 h-4 rounded bg-slate-200"></div>
              <div className="col-span-1 h-4 rounded bg-slate-200"></div>
              <div className="col-span-1 h-4 rounded bg-slate-200"></div>
              <div className="col-span-1 h-4 rounded bg-slate-200"></div>
            </div>
            <div className="h-4 rounded bg-slate-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const LoadingTable = ({ rows = 5, cols = 5, className = "h-5" }) => (
  <tbody className="divide-y-2">
    {[...Array(rows)].map((_, index) => (
      <tr key={index} className="space-y-3">
        {[...Array(cols)].map((_, index) => (
          <td key={index}>
            <span
              className={clsx(
                "animate-pulse block rounded bg-slate-100",
                className
              )}
            />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);
