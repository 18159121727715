import { useState } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_PROFILE } from "utils/mutations";
import { PROFILE } from "utils/queries";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";

export default function ProfileForm() {
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, setValue } = useForm();

  useQuery(PROFILE, {
    onCompleted: (data) => {
      const { username, email } = data.profile;
      setValue("profile.username", username);
      setValue("profile.email", email);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: (data) => {
      setLoader(false);
      if (data.updated?.success) toast.success("Profile updated successfully!");
      if (!data.updated?.success) toast.error("Profile update failed!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: PROFILE }],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { username, email } = data.profile;
    updateProfile({
      variables: {
        inputs: {
          username,
          email,
        },
      },
    });
  };

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Username</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Full name"
            className="input input-bordered w-full bg-white"
            required
            {...register("profile.username")}
          />
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="user@email.com"
            className="input input-bordered w-full bg-white"
            required
            {...register("profile.email")}
          />
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            className={`btn btn-primary btn-outline ${loader ? "loading" : ""}`}
          >
            Update Info
          </button>
        </div>
      </form>
    </div>
  );
}
