import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { CREATE_LOCATION, UPDATE_LOCATION } from "utils/mutations";
import { PRODUCT_CONSTANTS, GET_LOCATION, LIST_LOCATIONS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function LocationForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  useQuery(PRODUCT_CONSTANTS, {
    onCompleted: (data) => {
      setGroups(data?.groups);
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.created?.success)
        toast.error("Location creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("Location created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_LOCATIONS }],
  });

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: (data) => {
      setLoading(false);
      if (!data?.updated?.success)
        toast.error("Location update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("Location updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_LOCATIONS }],
  });

  const [getLocation] = useLazyQuery(GET_LOCATION, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.location?.success) {
        const { location } = data.location;
        setValue("location_name", location.name);
        setValue("location_username", location.username);
        setValue("location_email", location.email);
        setValue("location_type", location.type);
        setValue("location_stocking", location.stocking);
        setValue("location_xero", location.xero);
        setValue("location_restrictions", location.restrictions);
        setValue("location_status", location.status);
      }
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const onSubmit = (data) => {
    setLoading(true);

    const payload = {
      name: data.location_name,
      username: data.location_username,
      email: data.location_email,
      type: data.location_type,
      stocking: data.location_stocking === "true" ? true : false,
      xero: data.location_xero,
      restrictions: data.location_restrictions,
      status: data.location_status,
    };

    const inputs = id ? { id, ...payload } : payload;

    if (mode === "edit") updateLocation({ variables: { inputs } });
    else createLocation({ variables: { inputs } });
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getLocation({ variables: { id } });
    }
  }, [mode, id, getLocation]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* name */}
        <div>
          <label htmlFor="name" className="label">
            <span className="label-text font-semibold">Name</span>
          </label>
          <input
            type="text"
            name="name"
            placeholder="Location name"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_name", {
              required: "Name is required",
            })}
          />
          {errors?.location_name && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_name?.message}</small>
            </div>
          )}
        </div>
        {/* username */}
        <div>
          <label htmlFor="username" className="label">
            <span className="label-text font-semibold">Username</span>
          </label>
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_username", {
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
            })}
          />
          {errors?.location_username && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_username?.message}</small>
            </div>
          )}
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Location email"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email",
              },
            })}
          />
          {errors?.location_email && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_email?.message}</small>
            </div>
          )}
        </div>
        {/* type */}
        <div>
          <label htmlFor="type" className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            name="type"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_type", {
              required: "Type is required",
            })}
          >
            <option value="STORE">Store</option>
            <option value="WAREHOUSE">Warehouse</option>
            <option value="RESTAURANT">Restaurant</option>
            <option value="KITCHEN">Kitchen</option>
          </select>
          {errors?.location_type && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_type?.message}</small>
            </div>
          )}
        </div>
        {/* stocking */}
        <div>
          <label htmlFor="stocking" className="label">
            <span className="label-text font-semibold">Stocking</span>
          </label>
          <select
            name="stocking"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_stocking")}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
          {errors?.location_stocking && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_stocking?.message}</small>
            </div>
          )}
        </div>
        {/* xero (contact-id) */}
        <div>
          <label htmlFor="xero" className="label">
            <span className="label-text font-semibold">Xero Contact ID</span>
          </label>
          <input
            type="text"
            name="xero"
            placeholder="Xero contact ID"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("location_xero", {
              minLength: {
                value: 36,
                message: "Xero contact ID must be 36 characters",
              },
            })}
          />
          {errors?.location_xero && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_xero?.message}</small>
            </div>
          )}
        </div>
        {/* restrictions (groups - multiselect) */}
        <div>
          <label htmlFor="restrictions" className="label">
            <span className="label-text font-semibold">
              Request Restrictions
            </span>
          </label>
          <select
            name="restrictions"
            className="input input-bordered w-full bg-base-200 h-80 space-y-2"
            disabled={loading}
            {...register("location_restrictions")}
            multiple
          >
            {groups.map((group, index) => (
              <option key={index} value={group.value}>
                {group.module} / {group.label}
              </option>
            ))}
          </select>
          <label className="label">
            <span className="label-text-alt">
              Limit requestable product groups for this location
            </span>
          </label>
          {errors?.location_restrictions && (
            <div className="mt-1 text-red-600">
              <small>{errors?.location_restrictions?.message}</small>
            </div>
          )}
        </div>
        {/* status */}
        {mode === "edit" ? (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("location_status", {
                required: "Status is required",
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            {errors?.location_status && (
              <div className="mt-1 text-red-600">
                <small>{errors?.location_status?.message}</small>
              </div>
            )}
          </div>
        ) : null}
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update location" : "Create location"}
          </button>
        </div>
      </form>
    </div>
  );
}
