import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "utils/mutations";
import { GET_USER, LIST_USERS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function UserForm(props) {
  const { id } = props;

  const [mode] = useState(id ? "edit" : "create");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.created?.success)
        toast.error("User creation failed, please check inputs");
      if (data?.created?.success) {
        toast.success("User created successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);

      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_USERS }],
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      setLoading(false);

      if (!data?.updated?.success)
        toast.error("User update failed, please check inputs");
      if (data?.updated?.success) {
        toast.success("User updated successfully");
        reset();
        navigate(-1);
      }
    },
    onError: (error) => {
      setLoading(false);

      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_USERS }],
  });

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data) => {
      setLoading(false);
      if (data?.user?.success) {
        const { user } = data.user;
        setValue("user_username", user.username);
        setValue("user_email", user.email);
        setValue("user_role", user.role);
        setValue("user_status", user.status);
      }
    },
    onError: (error) => {
      setLoading(false);

      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (mode === "edit") {
      updateUser({
        variables: {
          inputs: {
            id,
            username: data.user_username,
            email: data.user_email,
            role: data.user_role,
            status: data.user_status,
          },
        },
      });
    } else {
      createUser({
        variables: {
          inputs: {
            username: data.user_username,
            email: data.user_email,
            role: data.user_role,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && mode === "edit") {
      setLoading(true);
      getUser({ variables: { id } });
    }
  }, [id, mode, getUser]);

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* username */}
        <div>
          <label htmlFor="username" className="label">
            <span className="label-text font-semibold">Username</span>
          </label>
          <input
            type="text"
            name="username"
            placeholder="Username"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("user_username", {
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
            })}
          />
          {errors?.user_username && (
            <div className="mt-1 text-red-600">
              <small>{errors?.user_username?.message}</small>
            </div>
          )}
        </div>
        {/* email */}
        <div>
          <label htmlFor="email" className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("user_email", {
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email",
              },
            })}
          />
          {errors?.user_email && (
            <div className="mt-1 text-red-600">
              <small>{errors?.user_email?.message}</small>
            </div>
          )}
        </div>
        {/* role */}
        <div>
          <label htmlFor="role" className="label">
            <span className="label-text font-semibold">Role</span>
          </label>
          <select
            name="role"
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("user_role", {
              required: "Role is required",
            })}
          >
            <option value="MANAGER">Manager</option>
            <option value="ADMIN">Admin</option>
          </select>
          {errors?.user_role && (
            <div className="mt-1 text-red-600">
              <small>{errors?.user_role?.message}</small>
            </div>
          )}
        </div>
        {/* status */}
        {mode === "edit" ? (
          <div>
            <label htmlFor="status" className="label">
              <span className="label-text font-semibold">Status</span>
            </label>
            <select
              name="status"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("user_status", {
                required: "Status is required",
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            {errors?.user_status && (
              <div className="mt-1 text-red-600">
                <small>{errors?.user_status?.message}</small>
              </div>
            )}
          </div>
        ) : null}
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx("btn btn-primary", `${loading ? "loading" : null}`)}
          >
            {mode === "edit" ? "Update user" : "Create user"}
          </button>
        </div>
      </form>
    </div>
  );
}
