import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_PRODUCTS } from "utils/queries";
import { DELETE_PRODUCT } from "utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";
import { LoadingTable } from "layouts/Loading";
import { statusTagColor, currency } from "utils/helpers";

export default function ProductsTable() {
  const [products, setProducts] = useState([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const [module, setModule] = useState(searchParams.get("module") || "ALL");
  const [name, setName] = useState("");

  const [debouncedName] = useDebounce(name, 1000);

  const { loading, refetch, startPolling, stopPolling } = useQuery(
    LIST_PRODUCTS,
    {
      onCompleted: (data) => {
        if (data?.products?.success) {
          setProducts(data?.products?.list);
          setTotal(data?.products?.total);
          setMaxPage(Math.ceil(data?.products?.total / limit));
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
      variables: {
        name: debouncedName !== "" ? debouncedName : null,
        module:
          searchParams.get("module") !== "ALL"
            ? searchParams.get("module")
            : null,
        limit,
        page,
      },
      fetchPolicy: "network-only",
    }
  );

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: (data) => {
      if (!data?.deleted?.success) toast.error("Product can not be deleted");
      if (data?.deleted?.success) {
        toast.success("Product deleted successfully");
        refetch();
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;
    deleteProduct({ variables: { id } });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  useEffect(() => {
    startPolling(60000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (module) setSearchParams({ module });
  }, [module, setSearchParams]);

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <header className="flex flex-col sm:flex-row justify-between p-2 gap-4">
        <div>
          <input
            className="input input-bordered w-full sm:w-80 bg-base-200 form-control"
            type="search"
            inputMode="search"
            placeholder="Filter by name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <select
            className="input input-bordered w-full sm:w-40 bg-base-200"
            name="module"
            value={module}
            onChange={(e) => setModule(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD">Food</option>
          </select>
        </div>
      </header>
      <main className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Name</th>
              <th className="text-xs">Group</th>
              <th className="text-xs text-center">Suppliers</th>
              <th className="text-xs">Module</th>
              <th className="text-center text-xs">Status</th>
              <th className="text-xs">
                <span className="pl-3">Action</span>
              </th>
            </tr>
          </thead>
          {loading ? (
            <LoadingTable rows={limit} cols={7} className="h-10" />
          ) : (
            <tbody className="divide-y-2">
              {products && products.length > 0 ? (
                products.map((value, index) => (
                  <tr key={index} className="text-left">
                    <td>{page * limit + index + 1}</td>
                    <td className="flex flex-row items-center rounded min-w-max">
                      <div className="flex-shrink-0 w-16">
                        <img
                          src={value.thumbnail}
                          className="min-w-16 min-h-16 w-16 h-16 object-cover"
                          alt={value.name}
                        />
                      </div>
                      <div className="flex-1 flex-shrink-0 p-4">
                        <h3 className="text-lg font-semibold tracking-wider">
                          {value.name}
                        </h3>
                        <p>
                          {value.unit}
                          {!!value.price && (
                            <span className="text-gray-500 font-mono">
                              {" "}
                              | {currency(value.price)}
                            </span>
                          )}
                        </p>
                      </div>
                    </td>
                    <td>{value.group}</td>
                    <td className="text-center">{value.count.suppliers}</td>
                    <th>{value.module}</th>
                    <td className="text-center">
                      <span
                        className={clsx(
                          "text-xs py-1 px-2 rounded",
                          `${statusTagColor(value.status)}`
                        )}
                      >
                        {value.status}
                      </span>
                    </td>
                    <td className="space-x-2">
                      <Link
                        className="btn btn-sm btn-ghost"
                        to={`/update/product/${value.id}`}
                      >
                        Update
                      </Link>
                      <button
                        className={clsx(
                          "btn btn-sm btn-ghost",
                          `${value.role === "ADMIN" ? "btn-disabled" : null}`
                        )}
                        onClick={() => handleDelete(value.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No products found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </main>
      <footer className="flex flex-col justify-between p-2">
        <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-4">
          <div>
            <p>
              Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
              <span className="font-bold">
                {page * limit + products.length}
              </span>{" "}
              of <span className="font-bold">{total}</span> results
            </p>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-outline btn-sm"
              disabled={page === 0 || maxPage <= 1}
              onClick={() => prevPage()}
            >
              Prev
            </button>
            <button
              className="btn btn-outline btn-sm"
              disabled={page === maxPage - 1 || maxPage <= 1}
              onClick={() => nextPage()}
            >
              Next
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
}
