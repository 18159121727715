import { useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_OPERATORS } from "utils/queries";
import { DELETE_OPERATOR } from "utils/mutations";
import clsx from "clsx";
import { statusTagColor } from "utils/helpers";

export default function OperatorsTable() {
  const [operators, setOperators] = useState([]);

  useQuery(LIST_OPERATORS, {
    onCompleted: (data) => {
      if (data?.operators?.success) setOperators(data?.operators?.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [deleteOperator] = useMutation(DELETE_OPERATOR, {
    onCompleted: (data) => {
      if (!data?.deleted?.success) toast.error("Operator can not be deleted");
      if (data?.deleted?.success) {
        toast.success("Operator deleted successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_OPERATORS }],
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this operator?"))
      return;
    deleteOperator({ variables: { id } });
  };

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <div className="flex flex-col md:flex-row justify-between p-2">
        <h1 className="subheading">Operator Users</h1>
        <Link className="btn btn-secondary btn-outline" to="/create/operator">
          Add operator
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Name</th>
              <th className="text-xs">Username</th>
              <th className="text-xs">Email</th>
              <th className="text-xs">Module</th>
              <th className="text-xs text-center">Status</th>
              <th className="text-xs">
                <span className="pl-3">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {operators && operators.length > 0 ? (
              operators.map((value, index) => (
                <tr key={index} className="text-left">
                  <td>{index + 1}</td>
                  <th>{value.name}</th>
                  <td>{value.username}</td>
                  <td>{value.email}</td>
                  <th>{value.module}</th>
                  <td className="text-center">
                    <span
                      className={clsx(
                        "text-xs py-1 px-2 rounded",
                        `${statusTagColor(value.status)}`
                      )}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td className="space-x-2">
                    <Link
                      className="btn btn-sm btn-ghost"
                      to={`/update/operator/${value.id}`}
                    >
                      Update
                    </Link>
                    <button
                      className={clsx(
                        "btn btn-sm btn-ghost",
                        `${value.role === "ADMIN" ? "btn-disabled" : null}`
                      )}
                      onClick={() => handleDelete(value.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No operators found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
