import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { AuthContext } from "contexts/Auth";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import clsx from "clsx";
import ScheduleTable from "components/tables/Schedule";
import OperatorsTable from "components/tables/Operators";
import LocationsTable from "components/tables/Locations";
import UsersTable from "components/tables/Users";

export default function Config(props) {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useState(user.data.role === "ADMIN");

  return (
    <UserLayout title="Config">
      <HeadSection heading="Config"></HeadSection>
      <section className="flex flex-col space-y-6 py-6">
        <ConfigTabs admin={isAdmin} />
      </section>
    </UserLayout>
  );
}

function ConfigTabs(props) {
  const { admin } = props;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (index) => {
    setSelectedIndex(index);
    if (index === 0) {
      setSearchParams({ tab: "schedule" });
    } else if (index === 1) {
      setSearchParams({ tab: "operators" });
    } else if (index === 2) {
      setSearchParams({ tab: "locations" });
    } else if (index === 3) {
      setSearchParams({ tab: "management" });
    } else {
      setSearchParams({ tab: null });
    }
  };

  const tabClasses = clsx(
    "w-full rounded py-2.5 text-xs font-medium tracking-wider leading-5 text-primary uppercase",
    "ui-selected:bg-white-shadow ui-selected:text-primary-content ui-selected:bg-primary",
    "hover:bg-white/[0.12] hover:text-primary-focus"
  );

  useEffect(() => {
    if (searchParams.get("tab") === "schedule") {
      setSelectedIndex(0);
    } else if (searchParams.get("tab") === "operators") {
      setSelectedIndex(1);
    } else if (searchParams.get("tab") === "locations") {
      setSelectedIndex(2);
    } else if (searchParams.get("tab") === "management") {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(0);
    }
  }, [searchParams]);

  return (
    <div className="w-full space-y-6 sm:px-0">
      <Tab.Group selectedIndex={selectedIndex} onChange={handleChange}>
        <div className="w-full max-w-lg">
          <Tab.List className="flex space-x-1 rounded bg-base-200 p-1">
            <Tab className={tabClasses}>Schedule</Tab>
            <Tab className={tabClasses}>Operators</Tab>
            <Tab className={tabClasses}>Locations</Tab>
            {admin ? <Tab className={tabClasses}>Management</Tab> : null}
          </Tab.List>
        </div>
        <Tab.Panels className="w-full mt-2">
          <Tab.Panel>
            <ScheduleTable />
          </Tab.Panel>
          <Tab.Panel>
            <OperatorsTable />
          </Tab.Panel>
          <Tab.Panel>
            <LocationsTable />
          </Tab.Panel>
          {admin ? (
            <Tab.Panel>
              <UsersTable />
            </Tab.Panel>
          ) : null}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
