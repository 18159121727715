import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_SUPPLIER } from "utils/mutations";
import { LIST_SUPPLIERS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { statusTagColor } from "utils/helpers";

export default function SuppliersTable() {
  const [suppliers, setSuppliers] = useState([]);
  const [list, setList] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [module, setModule] = useState(searchParams.get("module") || "ALL");

  useQuery(LIST_SUPPLIERS, {
    onCompleted: (data) => {
      if (data?.suppliers?.success) setSuppliers(data?.suppliers?.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    pollInterval: 60000,
  });

  const [deleteSupplier] = useMutation(DELETE_SUPPLIER, {
    onCompleted: (data) => {
      if (!data?.deleted?.success) toast.error("Supplier can not be deleted");
      if (data?.deleted?.success) {
        toast.success("Supplier deleted successfully");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: LIST_SUPPLIERS }],
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this supplier?"))
      return;
    deleteSupplier({ variables: { id } });
  };

  useEffect(() => {
    if (module) setSearchParams({ module });
  }, [module, setSearchParams]);

  useEffect(() => {
    const param = searchParams.get("module");
    if (param !== "ALL") {
      setList(suppliers?.filter((supplier) => supplier?.module === param));
    } else {
      setList(suppliers);
    }
  }, [searchParams, suppliers]);

  return (
    <div className="grid grid-cols-1 gap-4 bg-base-200 rounded p-4">
      <header className="flex flex-col md:flex-row justify-between p-2">
        <div></div>
        <div>
          <select
            className="input input-bordered w-full sm:w-40 bg-base-200"
            name="module"
            value={module}
            onChange={(e) => setModule(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="PRODUCE">Produce</option>
            <option value="GROCERY">Grocery</option>
            <option value="FOOD" disabled>
              Food
            </option>
          </select>
        </div>
      </header>
      <main className="overflow-x-auto">
        <table className="table-normal w-full divide-y-2">
          <thead>
            <tr className="text-left uppercase">
              <th className="text-xs">#</th>
              <th className="text-xs">Name</th>
              <th className="text-xs">Email</th>
              <th className="text-xs">Mobile</th>
              <th className="text-xs">Module</th>
              <th className="text-center text-xs">Status</th>
              <th className="text-xs">
                <span className="pl-3">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {list && list.length > 0 ? (
              list.map((value, index) => (
                <tr key={index} className="text-left">
                  <td>{index + 1}</td>
                  <th>
                    {value.name}{" "}
                    {value.availability && (
                      <small className="text-xs font-light">
                        / {value.availability}
                      </small>
                    )}
                  </th>
                  <td>{value.email ? value.email : "-"}</td>
                  <td>{value.mobile ? value.mobile : "-"}</td>
                  <th>{value.module}</th>
                  <td className="text-center">
                    <span
                      className={clsx(
                        "text-xs py-1 px-2 rounded",
                        `${statusTagColor(value.status)}`
                      )}
                    >
                      {value.status}
                    </span>
                  </td>
                  <td className="space-x-2">
                    <Link
                      className="btn btn-sm btn-ghost"
                      to={`/update/supplier/${value.id}`}
                    >
                      Update
                    </Link>
                    <button
                      className={clsx(
                        "btn btn-sm btn-ghost",
                        `${value.role === "ADMIN" ? "btn-disabled" : null}`
                      )}
                      onClick={() => handleDelete(value.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No suppliers found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </main>
    </div>
  );
}
