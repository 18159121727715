import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_SUPPLIERS, GET_PRODUCT, LIST_PRODUCTS } from "utils/queries";
import { UPDATE_PRODUCT } from "utils/mutations";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function ProductSuppliersForm(props) {
  const { product, list } = props;

  const { id, module } = product;

  const [allow, setAllow] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const { refetch } = useQuery(LIST_SUPPLIERS, {
    onCompleted: (data) => {
      setLoading(false);
      setSuppliers(data?.suppliers?.list);
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.message);
    },
    variables: {
      module,
      ...(list?.length > 0 && {
        exclude: list.map((value) => value.supplier?.id),
      }),
    },
    fetchPolicy: "network-only",
  });

  const [addProductSupplier, { loading: adding }] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted: (data) => {
        setLoading(false);

        if (!data?.updated?.success)
          toast.error("Failed to add product supplier, please check inputs");
        if (data?.updated?.success) {
          toast.success("Product supplier added successfully");
          refetch();
          reset();
        }
      },
      onError: (error) => {
        setLoading(false);
        toast.error(error.message);
      },
      refetchQueries: [
        { query: GET_PRODUCT, variables: { id } },
        { query: LIST_PRODUCTS, variables: { module } },
      ],
    }
  );

  const onSubmit = (data) => {
    setLoading(true);

    let percentage;
    if (module === "PRODUCE") percentage = data.product_new_percentage;
    if (module === "GROCERY") percentage = "100";

    addProductSupplier({
      variables: {
        inputs: {
          id,
          addSupplier: `${data.product_new_supplier}::${percentage}`,
        },
      },
    });
  };

  useEffect(() => {
    if (product.module === "PRODUCE" && product.suppliers?.length >= 6) {
      setAllow(false);
    } else if (product.module !== "PRODUCE" && product.suppliers?.length >= 1) {
      setAllow(false);
    } else {
      setAllow(true);
    }
  }, [product]);

  return (
    <div className="mt-2">
      {allow && suppliers?.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* supplier */}
          <div>
            <label htmlFor="supplier" className="label">
              <span className="label-text font-semibold">Add Supplier</span>
            </label>
            <select
              name="supplier"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("product_new_supplier", {
                required: "Supplier is required",
              })}
            >
              <option value="">Select supplier</option>
              {suppliers.map((supplier, index) => (
                <option
                  key={index}
                  value={
                    supplier.availability
                      ? `${supplier.id}::${supplier.availability}`
                      : `${supplier.id}::_`
                  }
                >
                  {supplier.name}{" "}
                  {supplier.availability && <>({supplier.availability})</>}
                </option>
              ))}
            </select>
            {errors?.product_new_supplier && (
              <div className="mt-1 text-red-600">
                <small>{errors?.product_new_supplier?.message}</small>
              </div>
            )}
          </div>
          {/* percentage */}
          {module === "PRODUCE" ? (
            <div>
              <label htmlFor="group" className="label">
                <span className="label-text font-semibold">Percentage</span>
                <span className="label-text-alt">(in %)</span>
              </label>
              <input
                type="number"
                inputMode="numeric"
                name="percentage"
                placeholder="Supplier percentage"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("product_new_percentage", {
                  required: "Percentage is required",
                  min: {
                    value: 1,
                    message: "Percentage must be greater than 0",
                  },
                  max: {
                    value: 100,
                    message: "Percentage must be less than 100",
                  },
                })}
              />
              {errors?.product_new_percentage && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.product_new_percentage?.message}</small>
                </div>
              )}
            </div>
          ) : null}
          {/* availability */}
          {/* submit */}
          <div className="pt-4">
            <button
              className={clsx(
                "btn btn-primary",
                `${adding ? "loading" : null}`
              )}
            >
              Add Supplier
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
